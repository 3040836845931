<template>
  <div>
    <navbar />
    <div v-if="events" class="content">
      <div class="search-filters">
        <div class="search-container">
          <input v-model="searchQuery" placeholder="Поиск по названию ..." class="search-input" />
          <button @click="showFiltersModal = true" class="filters-button">
            <i class="fas fa-filter"></i> Фильтры
          </button>
        </div>
      </div>
      <div>
        <div v-if="isAnyFilterApplied || searchQuery">
          <h2>Поиск</h2>
          <div v-if="filteredEvents.length > 0" class="events-grid">
            <EventCard v-for="event in filteredEvents" :key="event.id" :event="event"
              :getEventColumnValue="getEventColumnValue" @show-modal="showModal" />
          </div>
          <div v-else>
            <p>Нет мероприятий, соответствующих выбранным фильтрам</p>
          </div>
        </div>
        <div v-else>
          <h2>Сегодня</h2>
          <div v-if="todayEvents.length > 0" class="events-grid">
            <EventCard v-for="event in todayEvents" :key="event.id" :event="event"
              :getEventColumnValue="getEventColumnValue" @show-modal="showModal" />
          </div>
          <div v-else>
            <p>На сегодня мероприятия не запланированы</p>
          </div>

          <h2>Ближайшие мероприятия</h2>
          <div v-if="upcomingEvents.length > 0" class="events-grid">
            <EventCard v-for="event in upcomingEvents" :key="event.id" :event="event"
              :getEventColumnValue="getEventColumnValue" @show-modal="showModal" />
          </div>
          <div v-else>
            <p>Ближайших мероприятий нет</p>
          </div>

          <h2>Все мероприятия</h2>
          <div v-for="(events, month) in allEventsByMonth" :key="month">
            <h3>{{ month }}</h3>
            <div v-if="events.length > 0" class="events-grid">
              <EventCard v-for="event in events" :key="event.id" :event="event"
                :getEventColumnValue="getEventColumnValue" @show-modal="showModal" />
            </div>
            <div v-else>
              <p>Нет мероприятий в этом месяце</p>
            </div>
          </div>
        </div>
        <modal v-if="isModalVisible" @close="closeModal" :event="selectedEvent" :user="selectedUser"
          :fedDistricts="fedDistricts" :academicDegrees="academicDegrees" :academicTitles="academicTitles"
          :organizationTypes="organizationTypes" :participationForms="participationForms" />
      </div>
    </div>
    <div v-else>
      Loading event data...
    </div>

    <!-- Модальное окно с фильтрами -->
    <div v-if="showFiltersModal" class="modal-backdrop" @click="showFiltersModal = false">
      <div class="modal" @click.stop>
        <div class="modal-header">
          <h3>Фильтры</h3>
          <button @click="showFiltersModal = false" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="filter-grid">
            <div class="filter-item">
              <label for="dateRange">Дата:</label>
              <input type="date" id="dateRangeStart" v-model="dateRange[0]" />
              <input type="date" id="dateRangeEnd" v-model="dateRange[1]" />
            </div>
            <div class="filter-item">
              <label for="organizator">Организатор:</label>
              <select id="organizator" v-model="selectedOrganizator" multiple>
                <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
              </select>
            </div>
            <div class="filter-item">
              <label for="level">Уровень:</label>
              <select id="level" v-model="selectedLevel" multiple>
                <option v-for="lvl in Levels" :key="lvl.uid" :value="lvl.uid">{{ lvl.name }}</option>
              </select>
            </div>
            <div class="filter-item">
              <label for="subType">Подтип:</label>
              <select id="subType" v-model="selectedSubType" multiple>
                <option v-for="subType in EventSubTypes" :key="subType.uid" :value="subType.uid">{{ subType.name }}
                </option>
              </select>
            </div>
            <div class="filter-item">
              <label for="member">Участники:</label>
              <select id="member" v-model="selectedMember" multiple>
                <option v-for="mem in Members" :key="mem.uid" :value="mem.uid">{{ mem.name }}</option>
              </select>
            </div>
            <div class="filter-item">
              <label for="organizationForm">Форма организации:</label>
              <select id="organizationForm" v-model="selectedOrganizationForm" multiple>
                <option v-for="form in OrganizationForms" :key="form.uid" :value="form.uid">{{ form.name }}</option>
              </select>
            </div>
          </div>
          <button @click="resetFilters" class="reset-button">
            <i class="fas fa-trash-alt"></i> Сбросить все
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventCard from '@/components/EventCard.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from "@/components/navbar.vue";
import customfooter from "@/components/footer.vue";
import modal from '@/components/ModalQR.vue';
import moment from 'moment';

export default {
  name: 'EventsView',
  components: {
    navbar,
    EventCard,
    modal,
    customfooter,
  },
  data() {
    return {
      isModalVisible: false,
      selectedEvent: null,
      selectedUser: null,
      searchQuery: '',
      dateRange: [],
      selectedOrganizator: [],
      selectedLevel: [],
      selectedSubType: [],
      selectedMember: [],
      selectedOrganizationForm: [],
      showFiltersModal: false,
    };
  },
  computed: {
    ...mapState({
      events: state => state.event.events,
      organizators: state => state.references.organizators,
      addTypes: state => state.references.addTypes,
      EventClasses: state => state.references.EventClasses,
      EventTypes: state => state.references.EventTypes,
      Levels: state => state.references.Levels,
      EventSubTypes: state => state.references.EventSubTypes,
      Members: state => state.references.Members,
      OrganizationForms: state => state.references.OrganizationForms
    }),
    ...mapGetters({
      user: 'user/user'
    }),
    filteredEvents() {
      return this.events.filter(event => {
        const searchMatch = event.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                            event.description?.toLowerCase().includes(this.searchQuery.toLowerCase());
        const dateMatch = this.dateRange.length ? moment(event.datestart).isBetween(this.dateRange[0], this.dateRange[1], 'day', '[]') : true;
        const organizatorMatch = this.selectedOrganizator.length ? this.selectedOrganizator.includes(event.organizator) : true;
        const levelMatch = this.selectedLevel.length ? this.selectedLevel.includes(event.level) : true;
        const subTypeMatch = this.selectedSubType.length ? this.selectedSubType.includes(event.eventsubtype) : true;
        const memberMatch = this.selectedMember.length ? this.selectedMember.some(member => event.members.includes(member)) : true;
        const organizationFormMatch = this.selectedOrganizationForm.length ? this.selectedOrganizationForm.includes(event.organizationform) : true;
        return searchMatch && dateMatch && organizatorMatch && levelMatch && subTypeMatch && memberMatch && organizationFormMatch;
      });
    },
    todayEvents() {
      return this.filteredEvents.filter(event => moment(event.datestart).isSame(moment(), 'day'));
    },
    upcomingEvents() {
      const today = moment(); // Текущая дата

      // Фильтруем мероприятия, которые еще не прошли
      const upcoming = this.filteredEvents.filter(event => {
        if (event.datestart) {
          // Если есть точная дата, проверяем, что она в будущем
          return moment(event.datestart).isAfter(today);
        } else if (event.monthstart && event.yearstart) {
          // Если есть только месяц и год, проверяем, что месяц и год в будущем
          const eventDate = moment().month(event.monthstart - 1).year(event.yearstart);
          return eventDate.isAfter(today);
        }
        return false; // Если нет ни даты, ни месяца/года, не включаем в ближайшие
      });

      // Сортируем мероприятия:
      // 1. Сначала по точной дате (если есть).
      // 2. Затем по месяцу и году (если точной даты нет).
      upcoming.sort((a, b) => {
        const dateA = a.datestart 
          ? moment(a.datestart) 
          : moment().month(a.monthstart - 1).year(a.yearstart);
        const dateB = b.datestart 
          ? moment(b.datestart) 
          : moment().month(b.monthstart - 1).year(b.yearstart);

        return dateA.diff(dateB); // Сортируем по возрастанию даты
      });

      // Возвращаем первые 5 ближайших мероприятий
      return upcoming.slice(0, 5);
    },
    allEventsByMonth() {
      const eventsByMonth = this.filteredEvents.reduce((acc, event) => {
        // Если дата отсутствует, используем месяц и год из полей monthstart и yearstart
        const month = event.datestart 
          ? moment(event.datestart).format('MMMM') 
          : (event.monthstart && event.yearstart 
              ? moment().month(event.monthstart - 1).year(event.yearstart).format('MMMM') 
              : 'Неизвестный месяц');
        const monthName = month.charAt(0).toUpperCase() + month.slice(1);
        if (!acc[monthName]) {
          acc[monthName] = [];
        }
        acc[monthName].push(event);
        return acc;
      }, {});

      const sortedMonths = Object.keys(eventsByMonth).sort((a, b) => {
        return moment(a, 'MMMM').diff(moment(b, 'MMMM'));
      });

      const sortedEventsByMonth = {};
      sortedMonths.forEach(month => {
        sortedEventsByMonth[month] = eventsByMonth[month].sort((a, b) => {
          // Сортируем по дате, если она есть, иначе по monthstart и yearstart
          const dateA = a.datestart 
            ? moment(a.datestart) 
            : (a.monthstart && a.yearstart 
                ? moment().month(a.monthstart - 1).year(a.yearstart) 
                : moment()); // Используем текущую дату, если данные отсутствуют
          const dateB = b.datestart 
            ? moment(b.datestart) 
            : (b.monthstart && b.yearstart 
                ? moment().month(b.monthstart - 1).year(b.yearstart) 
                : moment()); // Используем текущую дату, если данные отсутствуют

          // Проверяем, что dateA и dateB являются объектами moment
          if (dateA && dateB && dateA.isValid() && dateB.isValid()) {
            return dateA.diff(dateB);
          } else {
            return 0; // Если даты некорректны, не меняем порядок
          }
        });
      });

      return sortedEventsByMonth;
    },
    isAnyFilterApplied() {
      return this.dateRange.length > 0 ||
             this.selectedOrganizator.length > 0 ||
             this.selectedLevel.length > 0 ||
             this.selectedSubType.length > 0 ||
             this.selectedMember.length > 0 ||
             this.selectedOrganizationForm.length > 0;
    }
  },
  methods: {
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
      getAllEvents: 'event/getallevents',
      getorganizators: 'references/getorganizators',
      getaddtypes: 'references/getaddtypes',
      geteventclasses: 'references/geteventclasses',
      geteventtypes: 'references/geteventtypes',
      getlevels: 'references/getlevels',
      geteventsubtypes: 'references/geteventsubtypes',
      getmembers: 'references/getmembers',
      getorganizationforms: 'references/getorganizationforms'
    }),
    showModal(data) {
      this.selectedEvent = data.event;
      this.selectedUser = data.user;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedEvent = null;
      this.selectedUser = null;
    },
    getEventColumnValue(col, event) {
      switch (col) {
        case 'name':
          return event.name;
        case 'date':
          return event.datestart 
            ? moment(event.datestart).format('DD.MM.YYYY') 
            : (event.monthstart && event.yearstart 
                ? `Дата на уточнении (${moment().month(event.monthstart - 1).format('MMMM')} ${event.yearstart})` 
                : 'Дата не указана');
        case 'timestart':
          return event.timestart ? moment(event.timestart, 'HH:mm:ss').format('HH:mm') : 'Время на уточнении';
        case 'timeend':
          return event.timeend ? moment(event.timeend, 'HH:mm:ss').format('HH:mm') : 'Время на уточнении';
        case 'description':
          return event.description;
        case 'organizator':
          return this.organizators.find(org => org.uid === event.organizator)?.fullname || 'Неизвестный организатор';
        case 'suborganizator':
          return Array.isArray(event.suborganizator) ? event.suborganizator.map(uid => this.organizators.find(org => org.uid === uid)?.fullname || 'Неизвестный организатор').join(', ') : 'Нет соорганизаторов';
        case 'location':
          return event.location;
        case 'eventclass':
          return this.EventClasses.find(cls => cls.uid === event.eventclass)?.name || 'Неизвестный класс мероприятия';
        case 'eventtype':
          return this.EventTypes.find(type => type.uid === event.eventtype)?.name || 'Неизвестный тип мероприятия';
        case 'level':
          return this.Levels.find(level => level.uid === event.level)?.name || 'Неизвестный уровень мероприятия';
        case 'eventsubtype':
          return this.EventSubTypes.find(subtype => subtype.uid === event.eventsubtype)?.name || 'Нет подтипов';
        case 'additionallinks':
          return Array.isArray(event.additionallinks) ? event.additionallinks.join(', ') : 'Нет дополнительных ссылок';
        case 'members':
          return Array.isArray(event.members) ? event.members.map(uid => this.Members.find(member => member.uid === uid)?.name || 'Неизвестная целевая аудитория').join(', ') : 'Нет целевой аудитории';
        case 'organizationform':
          return this.OrganizationForms.find(form => form.uid === event.organizationform)?.name || 'Неизвестная форма организации';
        case 'responsible':
          return event.responsible;
        default:
          return event[col];
      }
    },
    customDateFormatter(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    resetFilters() {
      this.dateRange = [];
      this.selectedOrganizator = [];
      this.selectedLevel = [];
      this.selectedSubType = [];
      this.selectedMember = [];
      this.selectedOrganizationForm = [];
    }
  },
  async beforeMount() {
    await this.getUserByUid();
    await this.getAllEvents();
    await Promise.all([
      this.getorganizators(),
      this.getaddtypes(),
      this.geteventclasses(),
      this.geteventtypes(),
      this.getlevels(),
      this.geteventsubtypes(),
      this.getmembers(),
      this.getorganizationforms()
    ]);
  }
}
</script>
<style scoped>
.search-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 70px;
}

.search-container {
  display: flex;
  align-items: center;
}

.download-buttons {
  display: flex;
  gap: 10px;
}

.download-button {
  padding: 10px 20px;
  margin: 5px 5px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #ef7d00;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  grid-gap: 24px;
  padding-inline: 70px;
}

@media screen and (max-width: 1450px) {
  .events-grid {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 900px) {
  .events-grid {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .events-grid {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    padding-inline: 20px;
  }
}

.content {
  margin-top: 70px;
  /* Отступ сверху для основного контента */
}

.events-grid h2 {
  grid-column: 1 / -1;
  /* Занимает всю ширину */
}

h2,
h3 {
  text-align: left;
  padding: 10px;
  padding-left: 70px;
}

/* Стили для поля поиска */
.search-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  /* Закругленные углы */
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  /* Плавные переходы */
}

.search-input:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  pointer-events: none;
  /* Иконка не будет реагировать на клики */
}

.filters-button {
  display: flex;
  flex-direction: row;
  background-color: #3c218c;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.filters-button:hover {
  background-color: #ef7d00;
}

/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Убедитесь, что это значение выше, чем у других элементов */
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
  /* Это значение должно быть выше, чем у backdrop */
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.close-button:hover {
  color: #333;
}



.filter-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.filter-item {
  display: flex;
  flex-direction: column;
}

.filter-item label {
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-item select,
.filter-item input[type="date"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.filter-item select {
  height: 100px;
}

.modal-body button {
  margin-top: 20px;
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-body button:hover {
  background-color: #ef7d00;
}

.reset-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c218c;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #ef7d00;
}

.reset-button i {
  margin-right: 5px;
}

/* Медиа-запросы для мобильных устройств */
@media screen and (max-width: 768px) {
  .modal {
    width: 90%;
    max-width: 90%;
    padding: 10px;
  }

  .filter-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .filter-item select {
    height: 80px;
  }

  .modal-body button,
  .reset-button {
    padding: 8px;
    font-size: 14px;
  }
}
</style>