import { createStore } from 'vuex'
import auth from '@/store/auth'
import user from '@/store/user'
import event from '@/store/event'
import comments from '@/store/comment'
import attendance from '@/store/attendance'
import eventregistration from './eventregistration'
import references from './references'
export default createStore({
  state() {
    return {
      // msalConfig: {
      //   auth: {
      //     clientId: '2d61a943-86ef-43c0-831b-e73bfb2607d9',
      //     authority: 'https://login.microsoftonline.com/common',
      //     // redirectUri: 'https://afishka.pspu.ru/DataAddforMS',
      //     redirectUri: 'http://localhost:8080/DataAddforMS',
      //   },
      //   cache: {
      //     cacheLocation: 'localStorage',
      //   },

      // },
      accessToken: ""
    };
  },
  getters: {
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    }
  },
  actions: {
  },
  modules: {
    auth: auth,
    user: user,
    event: event,
    comments: comments,
    references: references,
    attendance: attendance,
    eventregistration: eventregistration,
  }
})