<template>
  <div v-if="event" class="event-card" :class="{ 'past-event': isEventPast }">
    <div class="event-image-container" @click="goToEventDetails">
      <img v-if="event.imagename" :src="getImageSrc(event.imagename)" alt="Event Image" class="event-image" />
      <div v-else class="no-image">Изображение отсутствует</div>
    </div>
    <div class="event-details" @click="goToEventDetails">
      <h2 class="event-name">{{ event.name }}</h2>
      <p class="event-date">{{ formatDate(event.datestart, event.dateend, event.monthstart, event.yearstart) }}</p>
      <p class="event-time">{{ formatTime(event.timestart, event.timeend) }}</p>
    </div>
    <div class="event-actions">
      <div class="text-buttons">
        <button 
          class="reg-button" 
          @click="handleRegistrationClick" 
          :disabled="isEventPast" 
          :class="{ 'disabled-button': isEventPast }"
        >
          <i class="fa-solid fa-pen-to-square"></i> Регистрация
        </button>
        <button 
          class="qr-button" 
          @click="handleQRClick" 
          :disabled="isEventPast" 
          :class="{ 'disabled-button': isEventPast }"
        >
          <i class="fas fa-qrcode"></i> Получить QR
        </button>
      </div>
      <div class="image-buttons">
        <button class="like-button" :class="{ 'liked': isLiked }" @click="handleLikeClick">
          <i class="fa fa-heart"></i>
        </button>
        <button class="comment-button" @click="goToEventComments">
          <i class="fa fa-commenting"></i>
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    Loading event data...
  </div>

  <!-- Модальное окно регистрации -->
  <RegistrationModal v-if="showRegistrationModal" :event="event" :user="user" @close="closeRegistrationModal" />

  <!-- Модальное окно QR-кода -->
  <QRModal v-if="showQRModal" :event="event" @close="closeQRModal" />

  <!-- Модальное окно для предложения авторизации -->
  <AuthModal v-if="showAuthModal" @close="closeAuthModal" />
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';
import moment from 'moment';
import 'moment/locale/ru';
import RegistrationModal from './ModalRegistration.vue';
import QRModal from './ModalQR.vue';
import AuthModal from './AuthModal.vue'; // Импортируем компонент модального окна авторизации

export default {
  components: {
    RegistrationModal,
    QRModal,
    AuthModal
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLiked: false,
      showRegistrationModal: false,
      showQRModal: false,
      showAuthModal: false,
      user: null
    };
  },
  computed: {
    isEventPast() {
      // Если есть дата начала и время начала, проверяем, прошло ли мероприятие
      if (this.event.datestart && this.event.timestart) {
        const eventDateTime = moment(`${this.event.datestart} ${this.event.timestart}`, 'YYYY-MM-DD HH:mm:ss');
        return eventDateTime.isBefore(moment(), 'day');
      }
      // Если дата начала отсутствует, но есть месяц и год, проверяем, прошел ли месяц
      else if (this.event.monthstart && this.event.yearstart) {
        const eventDate = moment().month(this.event.monthstart - 1).year(this.event.yearstart);
        return eventDate.isBefore(moment(), 'month');
      }
      // Если нет ни даты, ни месяца/года, считаем мероприятие активным
      return false;
    },
    isFavorite() {
      return this.$store.state.user.favoriteEvents.some(favEvent => favEvent.uid === this.event.uid);
    }
  },
  methods: {
    formatDate(dateStart, dateEnd, monthStart, yearStart) {
      if (!dateStart && !dateEnd) {
        // Если даты нет, показываем месяц и год
        const monthNames = [
          "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
          "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];
        return `Дата проведения на уточнении (${monthNames[monthStart - 1]} ${yearStart})`;
      } else {
        // Если дата есть, форматируем её
        const startDate = moment(dateStart, 'YYYY-MM-DD');
        const endDate = moment(dateEnd, 'YYYY-MM-DD');
        return `${startDate.format('DD.MM.YY')} - ${endDate.format('DD.MM.YY')}`;
      }
    },
    formatTime(timeStart, timeEnd) {
      if (!timeStart && !timeEnd) {
        return "Время на уточнении";
      } else {
        return `${moment(timeStart, 'HH:mm:ss').format('HH:mm')} - ${moment(timeEnd, 'HH:mm:ss').format('HH:mm')}`;
      }
    },
    goToEventDetails() {
      this.$router.push({ name: 'EventDetails', params: { id: this.event.uid } });
    },
    goToEventComments() {
      this.$router.push({ name: 'EventDetails', params: { id: this.event.uid }, hash: '#comments' });
    },
    async toggleLike() {
      try {
        this.isLiked = !this.isLiked;
        await this.$store.dispatch('user/addToFavorites', this.event);
      } catch (error) {
        this.isLiked = !this.isLiked;
        console.error('Ошибка при добавлении/удалении из избранного:', error);
      }
    },
    getImageSrc(imageUrl) {
      try {
        return require(`@/assets/posters/${imageUrl}`);
      } catch (error) {
        console.error(`Ошибка при загрузке изображения: ${imageUrl}`, error);
        return null;
      }
    },
    async openRegistrationModal() {
      try {
        // Загрузка данных пользователя
        const user = await this.$store.dispatch('user/getUserByUid');
        this.user = user;

        // Загрузка данных для выпадающих списков
        await Promise.all([
          this.$store.dispatch('references/getfeddistricts'),
          this.$store.dispatch('references/getacademicdegrees'),
          this.$store.dispatch('references/getacademictitles'),
          this.$store.dispatch('references/getorganizationtypes'),
          this.$store.dispatch('references/getparticipationforms')
        ]);

        // Открытие модального окна после загрузки данных
        this.showRegistrationModal = true;
      } catch (error) {
        console.error('Ошибка при загрузке данных пользователя:', error);
      }
    },
    closeRegistrationModal() {
      this.showRegistrationModal = false;
    },
    openQRModal() {
      this.showQRModal = true;
    },
    closeQRModal() {
      this.showQRModal = false;
    },
    handleRegistrationClick() {
      if (this.$store.state.user.user) {
        this.openRegistrationModal();
      } else {
        this.showAuthModal = true;
      }
    },
    handleQRClick() {
      if (this.$store.state.user.user) {
        this.openQRModal();
      } else {
        this.showAuthModal = true;
      }
    },
    handleLikeClick() {
      if (this.$store.state.user.user) {
        this.toggleLike();
      } else {
        this.showAuthModal = true;
      }
    },
    closeAuthModal() {
      this.showAuthModal = false;
    }
  },
  watch: {
    isFavorite: {
      immediate: true,
      handler(newVal) {
        this.isLiked = newVal;
      }
    }
  },
  created() {
    this.$store.dispatch('user/getFavoriteEvents');
  }
};
</script>

<style scoped>
.past-event .event-image {
  opacity: 0.5;
}
.disabled-button {
  background-color: #ccc !important;
  color: #888 !important;
  cursor: not-allowed !important;
  pointer-events: none;
}
.event-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.event-image-container {
  height: 150px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.event-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.event-card:hover .event-image-container .event-image {
  transform: scale(1.1);
}

.event-details {
  background-color: #f5f5f5;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  text-align: center;
}

.event-name {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
}

.event-date-time {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.event-description {
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.event-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
}

.text-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.image-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.like-button,
.comment-button {
  transition: color 0.3s ease;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
}

.qr-button,
.reg-button {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}

.like-button:hover,
.qr-button:hover,
.comment-button:hover,
.reg-button:hover {
  transform: scale(1.1);
  color: #ef7d00;
}

.like-button.liked {
  color: #ef7d00;
}

@media screen and (max-width: 600px) {
  .event-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .event-image-container {
    height: 200px;
  }

  .event-image {
    height: 200px;
  }

  .event-name {
    font-size: 18px;
  }

  .event-date-time {
    font-size: 16px;
  }

  .event-description {
    font-size: 16px;
  }

  .event-actions {
    margin: 10px;
  }

  .qr-button,
  .reg-button {
    padding: 12px 24px;
  }
}
</style>