<template>
  <div class="report-builder">
    <h2>Отчет по мероприятиям</h2>
    <div v-if="!selectedEvent">
      <h3>Выберите мероприятие:</h3>
      <table class="events-table">
        <thead>
          <tr>
            <th>UID</th>
            <th>Название</th>
            <th>Дата</th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in events" :key="event.uid">
            <td>{{ event.uid }}</td>
            <td>{{ event.name }}</td>
            <td>{{ formatDate(event.date) }}</td>
            <td><button @click="selectEvent(event.uid)">Выбрать</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h3>Участники мероприятия "{{ selectedEvent.name }}"</h3>
      <button class="back-btn" @click="resetEventSelection">
        <i class="fas fa-arrow-left"></i> Вернуться к выбору мероприятия
      </button>

      <div v-if="!selectedReportType">
        <h4>Выберите тип отчета:</h4>
        <button @click="selectReportType('full')">Полный отчет о мероприятии</button>
        <button @click="selectReportType('info')">Отчет с информацией о мероприятии</button>
      </div>

      <div v-else>
        <button class="back-btn" @click="resetReportType">
          <i class="fas fa-arrow-left"></i> Вернуться к выбору типа отчета
        </button>

        <div v-if="selectedReportType === 'info'">
          <h4>Информация о мероприятии "{{ selectedEvent.name }}"</h4>
          <div class="column-selection">
            <h5>Выберите столбцы для отображения:</h5>
            <button @click="selectAll('events')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in eventColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.events" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <table class="info-table">
            <thead>
              <tr>
                <th>Атрибут</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="col in selectedColumns.events" :key="col">
                <td>{{ eventColumns.find(c => c.key === col).label }}</td>
                <td>{{ getEventColumnValue(col, selectedEvent) }}</td>
              </tr>
            </tbody>
          </table>
          <button @click="exportToExcel">Скачать в Excel</button> 
        </div>

        <div v-else-if="selectedReportType === 'full'" class="column-selection">
          <h4>Выберите столбцы для отчета:</h4>
          <div class="column-group">
            <h5>О мероприятии</h5>
            <button @click="selectAll('events')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in eventColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.events" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О пользователе</h5>
            <button @click="selectAll('users')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in userColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.users" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О регистрации</h5>
            <button @click="selectAll('registration')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in registrationColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.registration" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О присутствии</h5>
            <button @click="selectAll('attendance')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in attendanceColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.attendance" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
        </div>

        <div v-if="selectedReportType === 'full'">
          <h4>Отчет по мероприятию "{{ selectedEvent.name }}"</h4>
          <button @click="exportToExcel">Скачать в Excel</button> 
          <table class="full-report-table">
            <thead>
              <tr>
                <th v-for="col in selectedColumns.events" :key="col">{{ eventColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.users" :key="col">{{ userColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.registration" :key="col">{{ registrationColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.attendance" :key="col">{{ attendanceColumns.find(c => c.key === col).label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(attendance, index) in combinedData" :key="index">
                <td v-for="col in selectedColumns.events" :key="col">{{ getEventColumnValue(col, selectedEvent) }}</td>
                <td v-for="col in selectedColumns.users" :key="col">{{ attendance.user ? attendance.user[col] : '' }}</td>
                <td v-for="col in selectedColumns.registration" :key="col">{{ attendance.registration ? attendance.registration[col] : '' }}</td>
                <td v-for="col in selectedColumns.attendance" :key="col">
                  <template v-if="col === 'scannedenter' || col === 'scannedexit'">
                    {{ formatDateTime(attendance[col]) }}
                  </template>
                  <template v-else>
                    {{ attendance[col] }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx';
export default {
  data() {
    return {
      selectedEvent: null,
      selectedReportType: null,
      attendances: [],
      registrations: [],
      selectedColumns: {
        events: [],
        users: [],
        attendance: [],
        registration: []
      },
      eventColumns: [
        { key: 'uid', label: 'Уникальный идентификатор мероприятия' },
        { key: 'name', label: 'Название мероприятия' },
        { key: 'date', label: 'Дата мероприятия' },
        { key: 'timestart', label: 'Время начала мероприятия' },
        { key: 'timeend', label: 'Время окончания мероприятия' },
        { key: 'description', label: 'Описание мероприятия' },
        { key: 'imagename', label: 'Название файла с изображением мероприятия' },
        { key: 'organizator', label: 'Основной организатор мероприятия' },
        { key: 'suborganizator', label: 'Массив соорганизаторов мероприятия' },
        { key: 'location', label: 'Место проведения мероприятия' },
        { key: 'addtype', label: 'Тип добавления мероприятия' },
        { key: 'eventclass', label: 'Класс мероприятия' },
        { key: 'eventtype', label: 'Тип мероприятия' },
        { key: 'level', label: 'Уровень мероприятия' },
        { key: 'eventsubtype', label: 'Подтип мероприятия' },
        { key: 'additionallinks', label: 'Массив дополнительных ссылок' },
        { key: 'members', label: 'Целевая аудитория мероприятия' },
        { key: 'organizationform', label: 'Форма организации участия в мероприятии' },
        { key: 'responsible', label: 'Uid пользователя внесшего информацию о мероприятии' }
      ],
      userColumns: [
        { key: 'uid', label: 'Уникальный идентификатор пользователя' },
        { key: 'email', label: 'Электронная почта пользователя' },
        { key: 'name', label: 'Имя пользователя' },
        { key: 'surname', label: 'Фамилия пользователя' },
        { key: 'thirdname', label: 'Отчество пользователя' },
        { key: 'rolein', label: 'Роль пользователя в приложении' },
        { key: 'organization', label: 'Информация об организации пользователя' },
        { key: 'faculty', label: 'Информация о факультете пользователя' },
        { key: 'user_group', label: 'Информация об учебной группе пользователя' },
        { key: 'security', label: 'Информация об уровне доступа' },
        { key: 'country', label: 'Страна пользователя' },
      ],
      attendanceColumns: [
        // { key: 'useruid', label: 'UUID пользователя, пришедшего на мероприятие' },
        { key: 'scanuidenter', label: 'UUID пользователя, сканирующего QR пользователя на вход' },
        { key: 'scanuidexit', label: 'UUID пользователя, сканирующего QR пользователя на выход' },
        // { key: 'eventuid', label: 'UUID мероприятия, на которое пришел пользователь' },
        { key: 'scannedenter', label: 'Временная метка, когда был отсканирован QR-код на вход' },
        { key: 'scannedexit', label: 'Временная метка, когда был отсканирован QR-код на выход' },
        { key: 'eventrole', label: 'Роль на мероприятии' }
      ],
      registrationColumns: [
        // { key: 'lastname', label: 'Фамилия' },
        // { key: 'firstname', label: 'Имя' },
        // { key: 'middlename', label: 'Отчество' },
        { key: 'activitytype', label: 'Тип активности' },
        { key: 'educationlevel', label: 'Уровень образования' },
        { key: 'course', label: 'Курс' },
        { key: 'studydirection', label: 'Направление обучения' },
        { key: 'otheractivitytype', label: 'Другой тип активности' },
        { key: 'country', label: 'Страна' },
        { key: 'feddistrict', label: 'Федеральный округ' },
        { key: 'region', label: 'Регион' },
        { key: 'city', label: 'Город' },
        { key: 'phone', label: 'Телефон' },
        // { key: 'email', label: 'Email' },
        { key: 'academicdegree', label: 'Ученая степень' },
        { key: 'academictitle', label: 'Ученое звание' },
        { key: 'organizationtype', label: 'Тип организации' },
        { key: 'customorganizationtype', label: 'Другой тип организации' },
        { key: 'organizationname', label: 'Название организации' },
        { key: 'participationform', label: 'Форма участия' },
        { key: 'reporttopic', label: 'Тема доклада' }
      ]
    };
  },
  computed: {
  ...mapState('event', ['events']),
  ...mapState('eventregistration', ['registrations']), // Добавляем состояние для регистраций
  ...mapState('attendance', ['attendances']), // Добавляем состояние для присутствия
  ...mapState('references', [
    'organizators',
    'addTypes',
    'EventClasses',
    'EventTypes',
    'Levels',
    'EventSubTypes',
    'Members',
    'OrganizationForms'
  ]),
  combinedData() {
    const combined = this.attendances.map(attendance => {
      const registration = this.registrations.find(reg => reg.userid === attendance.useruid);
      return { ...attendance, registration };
    });
    // console.log('Combined Data:', combined); // Добавляем лог для проверки
    return combined;
  }
},
  methods: {
    formatDate(date) {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}.${month}.${year}`;
  },
  formatTime(time) {
    if (!time) return '';
    const t = new Date(time);
    const hours = String(t.getHours()).padStart(2, '0');
    const minutes = String(t.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  },
  formatDateTime(dateTime) {
    if (!dateTime) return '';
    const dt = new Date(dateTime);
    const day = String(dt.getDate()).padStart(2, '0');
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const hours = String(dt.getHours()).padStart(2, '0');
    const minutes = String(dt.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  },
  exportToExcel() {
  const ws = XLSX.utils.json_to_sheet(this.combinedData.map(row => {
    const rowData = {};
    this.selectedColumns.events.forEach(col => {
      rowData[this.eventColumns.find(c => c.key === col).label] = this.getEventColumnValue(col, this.selectedEvent);
    });
    this.selectedColumns.users.forEach(col => {
      rowData[this.userColumns.find(c => c.key === col).label] = row.user ? row.user[col] : '';
    });
    this.selectedColumns.registration.forEach(col => {
      rowData[this.registrationColumns.find(c => c.key === col).label] = row.registration ? row.registration[col] : '';
    });
    this.selectedColumns.attendance.forEach(col => {
      if (col === 'scannedenter' || col === 'scannedexit') {
        rowData[this.attendanceColumns.find(c => c.key === col).label] = this.formatDateTime(row[col]);
      } else {
        rowData[this.attendanceColumns.find(c => c.key === col).label] = row[col];
      }
    });
    return rowData;
  }));

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Отчет');
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `Отчет_${this.selectedEvent.name}.xlsx`;
  link.click();
  URL.revokeObjectURL(url);
},
    ...mapActions('event', ['getallevents']),
    ...mapActions('user', ['getUserByUidForReport']),
    ...mapActions('attendance', ['getAttendanceByEventUid']),
    ...mapActions('eventregistration', ['getEventRegistrationsByEventUid']), // Добавляем действие для загрузки регистраций
    ...mapActions('references', [
      'getorganizators',
      'getaddtypes',
      'geteventclasses',
      'geteventtypes',
      'getlevels',
      'geteventsubtypes',
      'getmembers',
      'getorganizationforms'
    ]),
    async loadEvents() {
      try {
        await this.getallevents();
        await Promise.all([
          this.getorganizators(),
          this.getaddtypes(),
          this.geteventclasses(),
          this.geteventtypes(),
          this.getlevels(),
          this.geteventsubtypes(),
          this.getmembers(),
          this.getorganizationforms()
        ]);
      } catch (error) {
        console.error('Ошибка при загрузке мероприятий:', error);
      }
    },
    async selectEvent(eventUid) {
  try {
    this.selectedEvent = this.events.find(event => event.uid === eventUid);

    const attendances = await this.getAttendanceByEventUid(eventUid);
    this.attendances = await Promise.all(attendances.map(async (attendance) => {
      const user = await this.getUserByUidForReport(attendance.useruid);
      return { ...attendance, user };
    }));

    const registrations = await this.getEventRegistrationsByEventUid(eventUid); // Загружаем данные о регистрации
    if (!registrations) {
      console.error('Регистрации не найдены для мероприятия:', eventUid);
      this.registrations = [];
      return;
    }
    // console.log('Регистрации для мероприятия:', registrations); // Логирование данных о регистрации
    this.registrations = await Promise.all(registrations.map(async (registration) => {
      const user = await this.getUserByUidForReport(registration.userid); // Исправлено на userid
      return { ...registration, user };
    }));
    // console.log('Registrations:', this.registrations); // Логирование данных о регистрации
  } catch (error) {
    console.error('Ошибка при загрузке участников мероприятия:', error);
  }
},
    resetEventSelection() {
      this.selectedEvent = null;
      this.attendances = [];
      this.registrations = [];
      this.selectedReportType = null;
    },
    selectReportType(type) {
      this.selectedReportType = type;
    },
    resetReportType() {
      this.selectedReportType = null;
    },
    selectAll(group) {
  if (group === 'events') {
    if (this.selectedColumns.events.length === this.eventColumns.length) {
      this.selectedColumns.events = [];
    } else {
      this.selectedColumns.events = this.eventColumns.map(col => col.key);
    }
  } else if (group === 'users') {
    if (this.selectedColumns.users.length === this.userColumns.length) {
      this.selectedColumns.users = [];
    } else {
      this.selectedColumns.users = this.userColumns.map(col => col.key);
    }
  } else if (group === 'attendance') {
    if (this.selectedColumns.attendance.length === this.attendanceColumns.length) {
      this.selectedColumns.attendance = [];
    } else {
      this.selectedColumns.attendance = this.attendanceColumns.map(col => col.key);
    }
  } else if (group === 'registration') {
    if (this.selectedColumns.registration.length === this.registrationColumns.length) {
      this.selectedColumns.registration = [];
    } else {
      this.selectedColumns.registration = this.registrationColumns.map(col => col.key);
    }
  }
  // console.log('Selected Columns:', this.selectedColumns); // Добавляем лог для проверки
},
getEventColumnValue(col, event) {
  switch (col) {
    case 'date':
      return this.formatDate(event[col]);
    case 'organizator':
      return this.organizators.find(org => org.uid === event[col])?.fullname || 'Неизвестный организатор';
    case 'suborganizator':
      return event[col]?.map(uid => this.organizators.find(org => org.uid === uid)?.fullname || 'Неизвестный организатор').join(', ') || 'Нет соорганизаторов';
    case 'addtype':
      return this.addTypes.find(type => type.uid === event[col])?.name || 'Неизвестный тип добавления';
    case 'eventclass':
      return this.EventClasses.find(cls => cls.uid === event[col])?.name || 'Неизвестный класс мероприятия';
    case 'eventtype':
      return this.EventTypes.find(type => type.uid === event[col])?.name || 'Неизвестный тип мероприятия';
    case 'level':
      return this.Levels.find(level => level.uid === event[col])?.name || 'Неизвестный уровень мероприятия';
    case 'eventsubtype':
      return this.EventSubTypes.find(subtype => subtype.uid === event[col])?.name || 'Неизвестный подтип мероприятия';
    case 'members':
      return event[col]?.map(uid => this.Members.find(member => member.uid === uid)?.name || 'Неизвестная целевая аудитория').join(', ') || 'Нет целевой аудитории';
    case 'organizationform':
      return this.OrganizationForms.find(form => form.uid === event[col])?.name || 'Неизвестная форма организации';
    default:
      return event[col];
  }
},
    getColumnLabel(group, key) {
      if (group === 'attendance') {
        return this.attendanceColumns.find(col => col.key === key)?.label || '';
      } else if (group === 'registration') {
        return this.registrationColumns.find(col => col.key === key)?.label || '';
      }
      return '';
    },
    getAttendanceColumnValue(col, attendance) {
      if (this.selectedReportType === 'attendance') {
        return attendance[col];
      } else if (this.selectedReportType === 'registration') {
        return registration[col];
      }
      return '';
    }
  },
  created() {
    this.loadEvents();
  }
}
</script>
  
  <style scoped>
  .report-builder {
    margin-top: 20px;
    font-size: 18px; /* Увеличенный шрифт */
  }
  
  h2, h3, h4, h5 {
    margin-bottom: 20px;
    text-align: left; /* Выравнивание по левому краю */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left; /* Выравнивание по левому краю */
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  button {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  .back-btn {
    margin-bottom: 20px;
    background-color: #dc3545;
  }
  
  .back-btn:hover {
    background-color: #c82333;
  }
  
  .column-selection {
    margin-bottom: 20px;
  }
  
  .column-group {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
  }
  
  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .checkbox-grid label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .info-table {
    margin-top: 20px;
  }
  
  .info-table th, .info-table td {
    text-align: left; /* Выравнивание по левому краю */
  }
  
  .registration-table {
    margin-top: 20px;
  }
  
  .registration-table th, .registration-table td {
    text-align: left; /* Выравнивание по левому краю */
  }
  </style>