<template>
  <div>
    <navbar />
    <div v-if="events" class="content">
      <div v-if="user">
        <div class="search-filters">
        <div class="search-container">
          <input v-model="searchQuery" placeholder="Поиск по названию ..." class="search-input" />
        </div>
      </div>
        <h2>Мои мероприятия</h2>
        <div v-if="myEvents.length > 0" class="events-grid">
          <MyEventCard v-for="event in myEvents" :key="event.id" :event="event" />
        </div>
        <div v-else>
          <p>У вас нет мероприятий в качестве ответственного</p>
        </div>
      </div>
      <div v-else class="auth-required-message">
        <h2>Для просмотра мероприятий необходимо авторизоваться</h2>
        <p>Пожалуйста, войдите в систему, чтобы продолжить.</p>
        <button class="form-btn" @click="goToLogin">
          <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
        </button>
      </div>
    </div>
    <div v-else>
      <p>Загрузка данных мероприятий...</p>
    </div>
    <!-- <customfooter /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from "@/components/navbar.vue";
import customfooter from "@/components/footer.vue";
import MyEventCard from '@/components/EventCardForAdmin.vue';
import moment from 'moment';

export default {
  name: 'MyEventsView',
  components: {
    navbar,
    MyEventCard,
    customfooter,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState({
      events: state => state.event.events,
    }),
    ...mapGetters({
      user: 'user/user'
    }),
    myEvents() {
      return this.events.filter(event => {
        const searchMatch = event.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                            event.description?.toLowerCase().includes(this.searchQuery.toLowerCase());
        const responsibleMatch = event.responsiblelogin && event.responsiblelogin.includes(this.user.email); // Добавляем проверку на null
        return searchMatch && responsibleMatch;
      });
    },
  },
  methods: {
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
      getAllEvents: 'event/getallevents',
    }),
    goToLogin() {
      this.$router.push('/'); // Переход на страницу авторизации
    },
  },
  async beforeMount() {
    await this.getUserByUid();
    await this.getAllEvents();
  }
}
</script>

<style scoped>
.events-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  grid-gap: 24px;
  padding-inline: 70px;
}

@media screen and (max-width: 1450px) {
  .events-grid  {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 900px) {
  .events-grid  {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}

.content {
  margin-top: 70px;
  /* Отступ сверху для основного контента */
}

.events-grid h2 {
  grid-column: 1 / -1;
  /* Занимает всю ширину */
}

h2, h3 {
  text-align: left;
  padding: 10px;
  padding-left: 70px;
}

/* Стили для поля поиска */
.search-container {
  position: relative;
  width: 60%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px; /* Закругленные углы */
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Плавные переходы */
}

.search-input:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  pointer-events: none; /* Иконка не будет реагировать на клики */
}

/* Стили для сообщения о необходимости авторизации */
.auth-required-message {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-required-message h2 {
  color: #3c218c;
  margin-bottom: 10px;
}

.auth-required-message p {
  color: #666;
  margin-bottom: 20px;
}

.auth-required-message .form-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-required-message .form-btn:hover {
  background-color: #ef7d00;
}
</style>