<template>
  <div v-if="event" class="event-card" :class="{ 'past-event': isEventPast }">
    <div class="event-image-container" @click="goToEventDetails">
      <img 
        v-if="event.imagename" 
        :src="getImageSrc(event.imagename)" 
        alt="Event Image" 
        class="event-image" 
      />
      <div v-else class="no-image">Изображение отсутствует</div>
    </div>
    <div class="event-details" @click="goToEventDetails">
      <h2 class="event-name">{{ event.name }}</h2>
      <p class="event-date">{{ formatDate(event.date, event.monthstart, event.yearstart) }}</p>
      <p class="event-time">{{ formatTime(event.timestart, event.timeend) }}</p>
    </div>
    <div class="event-actions">
      <button class="text-button" @click="editEventData">Редактировать данные о мероприятии</button>
      <button class="text-button" @click="openRegistrationReport">Отчет о регистрации</button>
      <button class="text-button" @click="openAttendanceReport">Отчет о посещении</button>
    </div>
  </div>
  <div v-else>
    Loading event data...
  </div>
  <ModalEditEvent 
    v-if="showEditModal" 
    :event="event" 
    @close="showEditModal = false" 
    @save="saveEvent" 
  />
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';
import moment from 'moment';
import 'moment/locale/ru';
import ModalEditEvent from '@/components/ModalEditEvent.vue';

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showEditModal: false
    };
  },
  components: {
    ModalEditEvent
  },
  computed: {
    isEventPast() {
      if (this.event.date && this.event.timestart) {
        const eventDateTime = moment(`${this.event.date} ${this.event.timestart}`, 'YYYY-MM-DD HH:mm:ss');
        return eventDateTime.isBefore(moment());
      }
      return false; // Если дата или время отсутствуют, считаем, что мероприятие не прошло
    },
  },
  methods: {
    formatDate(dateString, monthStart, yearStart) {
      if (!dateString) {
        // Если даты нет, показываем месяц и год
        const monthNames = [
          "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
          "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];
        return `Дата проведения на уточнении (${monthNames[monthStart - 1]} ${yearStart})`;
      } else {
        // Если дата есть, форматируем её
        const date = moment(dateString, 'YYYY-MM-DD');
        return date.format('D MMMM YYYY');
      }
    },
    formatTime(timeStart, timeEnd) {
      if (!timeStart && !timeEnd) {
        return "Время проведения на уточнении";
      } else {
        return `${moment(timeStart, 'HH:mm:ss').format('HH:mm')} - ${moment(timeEnd, 'HH:mm:ss').format('HH:mm')}`;
      }
    },
    goToEventDetails() {
      this.$router.push({ name: 'EventDetails', params: { id: this.event.uid } });
    },
    getImageSrc(imageUrl) {
      try {
        return require(`@/assets/posters/${imageUrl}`);
      } catch (error) {
        console.error(`Ошибка при загрузке изображения: ${imageUrl}`, error);
        return null;
      }
    },
    editEventData() {
      this.showEditModal = true;
    },
    openRegistrationReport() {
      this.$router.push({
        name: 'RegistrationReport',
        params: { eventId: this.event.uid, eventName: this.event.name }
      });
    },
    openAttendanceReport() {
      this.$router.push({
        name: 'AttendanceReport',
        params: { eventId: this.event.uid, eventName: this.event.name }
      });
    },
    saveEvent() {
      this.showEditModal = false;
      // Логика для обновления данных в базе данных
    }
  },
};
</script>
  
  <style scoped>
  .past-event .event-image {
    opacity: 0.5;
  }
  .event-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
  }
  
  .event-image-container {
    height: 150px;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .event-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    transition: transform 0.3s ease;
    border-radius: 10px;
  }
  
  .event-card:hover .event-image-container .event-image {
    transform: scale(1.1);
  }
  
  .event-details {
    background-color: #f5f5f5;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    text-align: center; /* Добавляем это свойство для центрирования текста */
  }
  
  .event-name {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px; /* Уменьшаем отступ между названием и датой */
  }
  
  .event-date-time {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .event-description {
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 4; */
    -webkit-box-orient: vertical;
  }
  
  .event-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
  }
  
  .text-button {
    background-color: #3c218c;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    width: 100%;
    text-align: center;
  }
  
  .text-button:hover {
    background-color: #ef7d00;
  }
  </style>