<template>
  <div>
    <navbar />
    <div class="attendance-history">
      <div v-if="!userLoading">
        <div v-if="user">
          <h1>Мероприятия, которые вы посетили</h1>
          <div v-if="loading">Загрузка данных...</div>
          <div v-else>
            <!-- Заголовок и поле для поиска -->
            <div class="search-section">
              <h2>Поиск</h2>
              <div class="search-container">
                <input
                  v-model="searchQuery"
                  type="text"
                  placeholder="Введите текст для поиска..."
                  class="search-input"
                />
              </div>
            </div>

            <!-- Заголовок и фильтры -->
            <div class="filters-section">
              <h2>Фильтры</h2>
              <div class="filters">
                <div class="filter" v-for="field in visibleFields" :key="field.key">
                  <label>{{ field.label }}:</label>
                  <select v-model="filters[field.key]">
                    <option value="">Все</option>
                    <option v-for="value in getUniqueValues(field.key)" :key="value" :value="value">
                      {{ value }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Заголовок и выбор дополнительных полей -->
            <div class="field-selector-section">
              <h2>Дополнительные поля</h2>
              <div class="field-selector">
                <button @click="toggleAllFields" class="select-all-btn">
                  {{ areAllFieldsSelected ? 'Убрать все' : 'Выбрать все' }}
                </button>
                <select v-model="selectedAdditionalFields" multiple>
                  <option v-for="field in additionalFields" :key="field.key" :value="field">
                    {{ field.label }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Заголовок и таблица -->
            <div class="table-section">
              <h2>Таблица посещений</h2>
              <table v-if="filteredAttendances.length > 0">
                <thead>
                  <tr>
                    <th v-for="field in visibleFields" :key="field.key" @click="sortBy(field.key)">
                      {{ field.label }} {{ sortIcon(field.key) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(att, index) in sortedAttendances" :key="index">
                    <td v-for="field in visibleFields" :key="field.key">
                      {{ field.key === 'eventuid' ? getEventName(att.eventuid) : field.key === 'scannedenter' || field.key === 'scannedexit' ? formatDate(att[field.key]) : att[field.key] }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="no-data">
                {{ searchQuery || isFiltered ? 'Ничего не найдено' : 'Нет данных о посещениях.' }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="auth-required-message">
          <h2>Для просмотра истории посещений необходимо авторизоваться</h2>
          <p>Пожалуйста, войдите в систему, чтобы продолжить.</p>
          <button class="form-btn" @click="goToLogin">
            <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
          </button>
        </div>
      </div>
      <div v-else>
        Загрузка данных о пользователе...
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '@/components/navbar.vue';

export default {
  data() {
    return {
      loading: true,
      userLoading: true, // Флаг загрузки данных о пользователе
      searchQuery: '', // Поле для поискового запроса
      filters: {}, // Фильтры для каждого поля
      sortField: '', // Поле для сортировки
      sortDirection: 'asc', // Направление сортировки (asc/desc)
      selectedAdditionalFields: [], // Выбранные дополнительные поля
      // Основные поля
      basicFields: [
        { key: 'eventuid', label: 'Мероприятие' },
        { key: 'eventrole', label: 'Роль' },
        { key: 'scannedenter', label: 'Время входа' },
        { key: 'scannedexit', label: 'Время выхода' },
      ],
      // Дополнительные поля
      additionalFields: [
        { key: 'scanuidenter', label: 'Сканирующий вход' },
        { key: 'scanuidexit', label: 'Сканирующий выход' },
      ],
    };
  },
  computed: {
    ...mapState('attendance', ['attendances']),
    ...mapState('user', ['user']),
    ...mapState('event', ['events']), // Добавляем события из хранилища
    // Видимые поля (основные + выбранные дополнительные)
    visibleFields() {
      return [...this.basicFields, ...this.selectedAdditionalFields];
    },
    // Проверка, выбраны ли все дополнительные поля
    areAllFieldsSelected() {
      return this.selectedAdditionalFields.length === this.additionalFields.length;
    },
    // Фильтрация данных на основе поискового запроса и фильтров
    filteredAttendances() {
      let filtered = this.attendances;

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter((att) => {
          return this.visibleFields.some((field) => {
            const value = field.key === 'eventuid' ? this.getEventName(att.eventuid) : att[field.key];
            return value?.toString().toLowerCase().includes(query);
          });
        });
      }

      // Применяем фильтры по столбцам
      this.visibleFields.forEach((field) => {
        if (this.filters[field.key]) {
          filtered = filtered.filter((att) => {
            const value = field.key === 'eventuid' ? this.getEventName(att.eventuid) : att[field.key];
            return value === this.filters[field.key];
          });
        }
      });

      return filtered;
    },
    // Сортировка данных
    sortedAttendances() {
      if (!this.sortField) return this.filteredAttendances;

      return this.filteredAttendances.sort((a, b) => {
        let fieldA, fieldB;

        if (this.sortField === 'eventuid') {
          fieldA = this.getEventName(a.eventuid).toLowerCase();
          fieldB = this.getEventName(b.eventuid).toLowerCase();
        } else {
          fieldA = a[this.sortField]?.toString().toLowerCase();
          fieldB = b[this.sortField]?.toString().toLowerCase();
        }

        if (fieldA < fieldB) return this.sortDirection === 'asc' ? -1 : 1;
        if (fieldA > fieldB) return this.sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    },
    // Проверка, применены ли фильтры
    isFiltered() {
      return Object.values(this.filters).some((filter) => filter !== '');
    },
  },
  async created() {
    try {
      // Ждем загрузки данных о пользователе
      await this.getUserByUid();
      this.userLoading = false; // Устанавливаем флаг загрузки в false

      if (this.user) {
        await this.fetchAttendances();
        await this.fetchEvents(); // Загружаем список мероприятий
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('attendance', ['getAttendanceByUserUid']),
    ...mapActions('user', ['getUserByUid']),
    ...mapActions('event', ['getallevents']), // Добавляем действие для загрузки мероприятий
    async fetchAttendances() {
      try {
        const userUid = this.user.uid;
        await this.getAttendanceByUserUid(userUid);
      } catch (error) {
        console.error('Ошибка при загрузке посещений:', error);
      }
    },
    async fetchEvents() {
      await this.getallevents(); // Загружаем все мероприятия
    },
    getEventName(eventId) {
      const event = this.events.find((e) => e.uid === eventId);
      return event ? event.name : 'Неизвестное мероприятие';
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0'); // День (двузначный)
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Месяц (двузначный)
      const year = String(d.getFullYear()).slice(-2); // Год (двузначный)
      const hours = String(d.getHours()).padStart(2, '0'); // Часы (двузначные)
      const minutes = String(d.getMinutes()).padStart(2, '0'); // Минуты (двузначные)
      const seconds = String(d.getSeconds()).padStart(2, '0'); // Секунды (двузначные)
      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    },
    goToLogin() {
      this.$router.push('/');
    },
    // Сортировка по столбцу
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }
    },
    // Иконка для сортировки
    sortIcon(field) {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      }
      return '';
    },
    // Получение уникальных значений для фильтров
    getUniqueValues(fieldKey) {
      return [...new Set(this.attendances.map((att) => {
        if (fieldKey === 'eventuid') {
          return this.getEventName(att.eventuid);
        } else if (fieldKey === 'scannedenter' || fieldKey === 'scannedexit') {
          return this.formatDate(att[fieldKey]); // Форматируем дату
        } else {
          return att[fieldKey];
        }
      }))];
    },
    // Переключение всех дополнительных полей
    toggleAllFields() {
      if (this.areAllFieldsSelected) {
        this.selectedAdditionalFields = [];
      } else {
        this.selectedAdditionalFields = [...this.additionalFields];
      }
    },
  },
  components: {
    navbar,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.attendance-history {
  padding: 20px;
  margin-top: 10px;
}

.search-section,
.filters-section,
.field-selector-section,
.table-section {
  margin-bottom: 30px;
}

h2 {
  color: #3c218c;
  margin-bottom: 10px;
}

.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter {
  flex: 1;
  min-width: 200px;
}

.filter label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.filter select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.field-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.field-selector select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.select-all-btn {
  padding: 8px 16px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.select-all-btn:hover {
  background-color: #ef7d00;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #3c218c;
  color: white;
  cursor: pointer;
}

th:hover {
  background-color: #2a165f;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.no-data {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
}

.auth-required-message {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-required-message h2 {
  color: #3c218c;
  margin-bottom: 10px;
}

.auth-required-message p {
  color: #666;
  margin-bottom: 20px;
}

.auth-required-message .form-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-required-message .form-btn:hover {
  background-color: #ef7d00;
}
</style>