<template>
  <div>
    <navbar />
    <div v-if="isAuthorized" class="attendance-report">
      <h1>Отчет о присутствии на мероприятие "{{ eventId }}"</h1>
      <button @click="downloadExcel">Скачать в Excel</button>
      <table>
        <thead>
          <tr>
            <th>Пользователь</th>
            <th>Сканер вход</th>
            <th>Сканер выход</th>
            <th>Время входа</th>
            <th>Время выхода</th>
            <th>Роль</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attendance in attendances" :key="attendance.id">
            <td>{{ attendance.useruid }}</td>
            <td>{{ attendance.scanuidenter }}</td>
            <td>{{ attendance.scanuidexit }}</td>
            <td>{{ formatDate(attendance.scannedenter) }}</td>
            <td>{{ attendance.scannedexit ? formatDate(attendance.scannedexit) : 'Не зафиксировано' }}</td>
            <td>{{ attendance.eventrole }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="auth-required-message">
      <h2>Доступ запрещен</h2>
      <p v-if="!user">Для просмотра этой страницы необходимо авторизоваться.</p>
      <p v-else>У вас недостаточно прав для просмотра этой страницы.</p>
      <button class="form-btn" @click="goToLogin">
        <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
      </button>
    </div>
  </div>
</template>

<script>
import navbar from "@/components/navbar.vue";
import { mapActions, mapGetters } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  components: {
    navbar,
  },
  props: {
    eventName: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('attendance', ['attendances']),
    ...mapGetters('user', ['user']), // Добавляем состояние пользователя
    isAuthorized() {
      // Проверяем, авторизован ли пользователь и имеет ли он права администратора
      return this.user && this.user.security === 'admin';
    },
  },
  methods: {
    ...mapActions('attendance', ['getAttendanceByEventUid']),
    downloadExcel() {
      const data = this.attendances.map(attendance => ({
        'Пользователь': attendance.useruid,
        'Сканер вход': attendance.scanuidenter,
        'Сканер выход': attendance.scanuidexit,
        'Время входа': attendance.scannedenter,
        'Время выхода': attendance.scannedexit,
        'Роль': attendance.eventrole,
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Присутствие');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer, 'attendance.xlsx');
    },
    saveAsExcelFile(buffer, fileName) {
      const data = new Blob([buffer], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    goToLogin() {
      this.$router.push('/login'); // Переход на страницу авторизации
    },
  },
  async created() {
    if (this.isAuthorized) {
      await this.getAttendanceByEventUid(this.eventId);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.attendance-report {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* Стили для сообщения о необходимости авторизации */
.auth-required-message {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-required-message h2 {
  color: #3c218c;
  margin-bottom: 10px;
}

.auth-required-message p {
  color: #666;
  margin-bottom: 20px;
}

.auth-required-message .form-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-required-message .form-btn:hover {
  background-color: #ef7d00;
}
</style>