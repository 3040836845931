<template>
    <div class="content">
        <div class="required-note">
            <span class="required">*</span> — обязательные поля для заполнения
        </div>
        <div class="home">
            <form @submit.prevent="go_register">
                <h2>Уточните ваши данные для aFishka</h2>
                <div v-if="account" class="account-info">
                    <p>Вы вошли как: {{ account.username }}</p>
                    <p>Фамилия: {{ mssurname }}</p>
                    <p>Имя: {{ msname }}</p>
                    <p>Отчество: {{ msthirdname }}</p>
                    <p>Организация: ПГГПУ</p>
                    <p>Ваш логин для входа: {{ account.username }}</p>
                </div>
                <p>
                    Придумайте пароль для aFishka. В дальнейшем ваш логин Microsoft и пароль для aFishka будут
                    использоваться для входа в систему.
                </p>
                <!-- Пароль -->
                <div class="form-group">
                    <label for="password">Пароль <span class="required">*</span></label>
                    <div class="password-input">
                        <input id="password" :type="passwordFieldType" class="form-input" placeholder="Введите пароль"
                            required minlength="8" maxlength="40" v-model="password" />
                        <button type="button" class="eye-button" @click="togglePasswordVisibility">
                            <span v-if="passwordFieldType === 'password'">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span v-else>
                                <i class="fas fa-eye-slash"></i>
                            </span>
                        </button>
                    </div>
                    <div class="password-hint">Пароль должен быть не короче 8 символов</div>
                    <!-- Шкала надежности пароля -->
                    <div class="password-strength">
                        <div :class="['strength-bar', `strength-${passwordStrength}`]"></div>
                        <div class="strength-status">{{ passwordStatus }}</div>
                    </div>
                </div>
                <!-- Подтверждение пароля -->
                <div class="form-group">
                    <label for="confirmPassword">Подтверждение пароля <span class="required">*</span></label>
                    <div class="password-input">
                        <input id="confirmPassword" :type="confirmPasswordFieldType" class="form-input"
                            placeholder="Подтвердите пароль" required minlength="8" maxlength="40"
                            v-model="confirmPassword" />
                        <button type="button" class="eye-button" @click="toggleConfirmPasswordVisibility">
                            <span v-if="confirmPasswordFieldType === 'password'">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span v-else>
                                <i class="fas fa-eye-slash"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <!-- Роль -->
                <div class="form-group">
                    <label for="rolein">Роль в ПГГПУ <span class="required">*</span></label>
                    <select id="rolein" class="form-input" required v-model="rolein">
                        <option value="" disabled selected>Выберите роль</option>
                        <option value="Студент ПГГПУ">Студент ПГГПУ</option>
                        <option value="Преподаватель ПГГПУ">Преподаватель ПГГПУ</option>
                        <option value="Сотрудник ПГГПУ">Сотрудник ПГГПУ</option>
                    </select>
                </div>
                <!-- Факультет -->
                <div class="form-group">
                    <label for="faculty">Факультет</label>
                    <select id="faculty" class="form-input" v-model="faculty">
                        <option value="" disabled selected>Выберите факультет</option>
                        <option value="МФ">МФ</option>
                        <option value="ФФ">ФФ</option>
                        <option value="ФФК">ФФК</option>
                        <option value="ПиМНО">ПиМНО</option>
                        <option value="ППД">ППД</option>
                        <option value="ЕНФ">ЕНФ</option>
                        <option value="ИнЯз">ИнЯз</option>
                        <option value="ФизФ">ФизФ</option>
                        <option value="ИстФ">ИстФ</option>
                        <option value="МузФ">МузФ</option>
                        <option value="ПсФ">ПсФ</option>
                        <option value="ИНЭК">ИНЭК</option>
                        <option value="ПиСПО">ПиСПО</option>
                    </select>
                </div>
                <!-- Группа -->
                <div class="form-group">
                    <label for="user_group">Группа</label>
                    <input id="user_group" type="text" class="form-input" placeholder="Введите группу"
                        v-model="user_group" />
                </div>
                <!-- Кнопка регистрации -->
                <button type="submit" class="form-btn">Продолжить</button>
                <!-- Кнопка возврата -->
                <button type="button" @click="logout" class="form-btn">Вернуться на страницу регистрации</button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PublicClientApplication } from '@azure/msal-browser';
import zxcvbn from 'zxcvbn';

export default {
    data() {
        return {
            account: undefined,
            email: '',
            password: '',
            confirmPassword: '',
            surname: '',
            name: '',
            thirdname: '',
            rolein: '',
            organization: 'ПГГПУ',
            faculty: '',
            user_group: '',
            passwordFieldType: 'password',
            confirmPasswordFieldType: 'password',
        };
    },
    async created() {
        this.$msalInstance = new PublicClientApplication(
            this.$store.state.msalConfig,
        );
    },
    mounted() {
        const accounts = this.$msalInstance.getAllAccounts();
        if (accounts.length == 0) {
            return;
        }
        this.account = accounts[0];
        this.$emitter.emit('login', this.account);
        const [mssurname, msname, msthirdname] = this.account.name.split(" ");
        this.mssurname = mssurname;
        this.msname = msname;
        this.msthirdname = msthirdname;
    window.scrollTo(0, 0);
    },
    computed: {
        passwordStrength() {
            const result = zxcvbn(this.password);
            return result.score; // Возвращает оценку от 0 до 4
        },
        passwordStatus() {
            switch (this.passwordStrength) {
                case 0:
                    return 'Очень слабый';
                case 1:
                    return 'Слабый';
                case 2:
                    return 'Средний';
                case 3:
                    return 'Надежный';
                case 4:
                    return 'Очень надежный';
                default:
                    return '';
            }
        },
    },
    methods: {
        ...mapActions({
            register: 'auth/register',
            logoutAction: 'user/logout'
        }),
        async go_register() {
            if (this.password !== this.confirmPassword) {
                alert('Пароли не совпадают');
                return;
            }
            const formData = {
                email: this.account.username,
                password: this.password,
                surname: this.mssurname,
                name: this.msname,
                thirdname: this.msthirdname,
                rolein: this.rolein,
                organization: this.organization,
                faculty: this.faculty || "Не указано",
                user_group: this.user_group || "Не указано",
                authType: "MS",
                security: "defaultuser",
                country: 'Россия',
            };
            try {
                await this.register(formData);
                alert('Вы успешно зарегистрировались!');
                this.$router.push('/');
            } catch (error) {
                if (error.response && error.response.status === 405) {
                    alert('Пользователь с таким email уже зарегистрирован.');
                } else {
                    alert('Ошибка регистрации: ' + (error.response ? error.response.data.message : error.message));
                }
            }
        },
        async logout() {
            try {
                await this.logoutAction();
                localStorage.clear();
                this.$store.commit('user/setUser', null);
                this.$router.push('/register');
                window.alert('Вы прервали процесс регистрации');
            } catch (error) {
                console.log(error);
            }
        },
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        toggleConfirmPasswordVisibility() {
            this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
        },
    },
}
</script>

<style scoped>
/* Добавьте стили для шкалы надежности пароля и других элементов */
.password-strength {
    margin-top: 10px;
}
.strength-bar {
    height: 5px;
    background-color: #ddd;
    border-radius: 2px;
    margin-top: 5px;
}
.strength-0 { width: 20%; background-color: #ff4d4d; }
.strength-1 { width: 40%; background-color: #ff8c1a; }
.strength-2 { width: 60%; background-color: #ffd633; }
.strength-3 { width: 80%; background-color: #99cc00; }
.strength-4 { width: 100%; background-color: #33cc33; }
.strength-status {
    margin-top: 5px;
    font-size: 12px;
    color: #666;
}
.content {
    margin-top: 70px;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    height: 100vh;
}

.home {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.account-info {
    margin-bottom: 20px;
}

form {
    height: auto;
    width: 30%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    row-gap: 15px;
    border: 1px solid #ef7d00;
    border-radius: 20px;
    background-color: #fff;
}

@media screen and (max-width: 768px) {
    form {
        width: 80%;
        padding: 15px;
    }
}

.password-input {
    position: relative;
    display: flex;
    align-items: center;
}

.eye-button {
    background: none;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 1em;
    margin-left: -30px;
    z-index: 10;
}

.form-input {
    padding: 15px;
    border: none;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f5f5f5;
}

.form-btn {
    background-color: #ef7d00;
    color: white;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    margin: 10px auto;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.form-btn:hover {
    background-color: #3c218c;
}

a {
    color: #3c218c;
    text-decoration: none;
}

a:hover {
    color: #ef7d00;
}

h2 {
    color: #000000;
    font-size: 2em;
}

p {
    max-width: 500px;
    margin: 0 auto;
    padding: 15px;
    text-align: justify;
}
</style>
