export default {
  name: 'references',
  state: {
    organizators: [],
    suborganizators: [],
    addTypes: [],
    EventClasses: [],
    EventTypes: [],
    Levels: [],
    EventSubTypes: [],
    Members: [],
    OrganizationForms: [],
    fedDistricts: [],
    regions: [],
    academicDegrees: [],
    academicTitles: [],
    organizationTypes: [],
    participationForms: [],
    countries: [] // Добавляем состояние для стран
  },
  mutations: {
    SET_ORGANIZATORS(state, organizators) {
      state.organizators = organizators;
    },
    SET_SUBORGANIZATORS(state, suborganizators) {
      state.suborganizators = suborganizators;
    },
    SET_ADD_TYPES(state, addTypes) {
      state.addTypes = addTypes;
    },
    SET_EVENT_CLASSES(state, EventClasses) {
      state.EventClasses = EventClasses;
    },
    SET_EVENT_TYPES(state, EventTypes) {
      state.EventTypes = EventTypes;
    },
    SET_LEVELS(state, Levels) {
      state.Levels = Levels;
    },
    SET_EVENT_SUB_TYPES(state, EventSubTypes) {
      state.EventSubTypes = EventSubTypes;
    },
    SET_MEMBERS(state, Members) {
      state.Members = Members;
    },
    SET_ORGANIZATION_FORMS(state, OrganizationForms) {
      state.OrganizationForms = OrganizationForms;
    },
    SET_FED_DISTRICTS(state, fedDistricts) {
      state.fedDistricts = fedDistricts;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    SET_ACADEMIC_DEGREES(state, academicDegrees) {
      state.academicDegrees = academicDegrees;
    },
    SET_ACADEMIC_TITLES(state, academicTitles) {
      state.academicTitles = academicTitles;
    },
    SET_ORGANIZATION_TYPES(state, organizationTypes) {
      state.organizationTypes = organizationTypes;
    },
    SET_PARTICIPATION_FORMS(state, participationForms) {
      state.participationForms = participationForms;
    },
    SET_COUNTRIES(state, countries) { // Мутация для обновления списка стран
      state.countries = countries;
    }
  },
  actions: {
    async getorganizators({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/organizators`);
        const organizators = await response.json();
        commit('SET_ORGANIZATORS', organizators);
      } catch (error) {
        console.error('Ошибка при получении организаторов:', error);
      }
    },
    async getsuborganizators({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/organizators`);
        const suborganizators = await response.json();
        commit('SET_SUBORGANIZATORS', suborganizators);
      } catch (error) {
        console.error('Ошибка при получении соорганизаторов:', error);
      }
    },
    async getaddtypes({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/eventaddmethods`);
        const addTypes = await response.json();
        commit('SET_ADD_TYPES', addTypes);
      } catch (error) {
        console.error('Ошибка при получении типов добавления:', error);
      }
    },
    async geteventclasses({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/eventclasses`);
        const EventClasses = await response.json();
        commit('SET_EVENT_CLASSES', EventClasses);
      } catch (error) {
        console.error('Ошибка при получении классов мероприятий:', error);
      }
    },
    async geteventtypes({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/eventtypes`);
        const EventTypes = await response.json();
        commit('SET_EVENT_TYPES', EventTypes);
      } catch (error) {
        console.error('Ошибка при получении типов мероприятий:', error);
      }
    },
    async getlevels({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/eventlevels`);
        const Levels = await response.json();
        commit('SET_LEVELS', Levels);
      } catch (error) {
        console.error('Ошибка при получении уровней:', error);
      }
    },
    async geteventsubtypes({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/eventsubtypes`);
        const EventSubTypes = await response.json();
        commit('SET_EVENT_SUB_TYPES', EventSubTypes);
      } catch (error) {
        console.error('Ошибка при получении подтипов мероприятий:', error);
      }
    },
    async getmembers({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/members`);
        const Members = await response.json();
        commit('SET_MEMBERS', Members);
      } catch (error) {
        console.error('Ошибка при получении участников:', error);
      }
    },
    async getorganizationforms({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/organizationforms`);
        const OrganizationForms = await response.json();
        commit('SET_ORGANIZATION_FORMS', OrganizationForms);
      } catch (error) {
        console.error('Ошибка при получении форм организации:', error);
      }
    },
    async getfeddistricts({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/feddistricts`);
        const fedDistricts = await response.json();
        commit('SET_FED_DISTRICTS', fedDistricts);
      } catch (error) {
        console.error('Ошибка при получении федеральных округов:', error);
      }
    },
    async getregions({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/regions`);
        const regions = await response.json();
        commit('SET_REGIONS', regions);
      } catch (error) {
        console.error('Ошибка при получении всех регионов:', error);
      }
    },
    async getregionsbyfeddistrictid({ commit }, { feddistrictid }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/regions/byfeddistrict?feddistrictid=${feddistrictid}`);
        const regions = await response.json();
        commit('SET_REGIONS', regions);
      } catch (error) {
        console.error('Ошибка при получении регионов по федеральному округу:', error);
      }
    },
    async getacademicdegrees({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/academicdegrees`);
        const academicDegrees = await response.json();
        commit('SET_ACADEMIC_DEGREES', academicDegrees);
      } catch (error) {
        console.error('Ошибка при получении академических степеней:', error);
      }
    },
    async getacademictitles({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/academictitles`);
        const academicTitles = await response.json();
        commit('SET_ACADEMIC_TITLES', academicTitles);
      } catch (error) {
        console.error('Ошибка при получении академических званий:', error);
      }
    },
    async getorganizationtypes({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/organizationtypes`);
        const organizationTypes = await response.json();
        commit('SET_ORGANIZATION_TYPES', organizationTypes);
      } catch (error) {
        console.error('Ошибка при получении типов организаций:', error);
      }
    },
    async getparticipationforms({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/participationforms`);
        const participationForms = await response.json();
        commit('SET_PARTICIPATION_FORMS', participationForms);
      } catch (error) {
        console.error('Ошибка при получении форм участия:', error);
      }
    },
    async getcountries({ commit }) { // Действие для получения списка стран
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/references/countries`);
        const countries = await response.json();
        commit('SET_COUNTRIES', countries);
      } catch (error) {
        console.error('Ошибка при получении списка стран:', error);
      }
    }
  },
  getters: {
    organizators: state => state.organizators,
    addTypes: state => state.addTypes,
    EventClasses: state => state.EventClasses,
    EventTypes: state => state.EventTypes,
    Levels: state => state.Levels,
    EventSubTypes: state => state.EventSubTypes,
    Members: state => state.Members,
    OrganizationForms: state => state.OrganizationForms,
    fedDistricts: state => state.fedDistricts,
    regions: state => state.regions,
    academicDegrees: state => state.academicDegrees,
    academicTitles: state => state.academicTitles,
    organizationTypes: state => state.organizationTypes,
    participationForms: state => state.participationForms,
    countries: state => state.countries // Геттер для доступа к списку стран
  },
  namespaced: true
};