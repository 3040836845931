<template>
  <navbar />
  <div class="event-details-container" v-if="event">
    <div class="backimage"
      :style="{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${getImageSrc(event.imagename)})` }">
      <div class="image-container">
        <img v-if="getImageSrc(event.imagename)" :src="getImageSrc(event.imagename)" alt="Event Image"
          class="event-image" />
        <div v-else class="no-image">Изображение отсутствует</div>
      </div>
      <div class="event-details">
        <h1>{{ event.name }}</h1>
        <p class="event-date">Дата проведения: {{ formatDate(event.datestart, event.dateend, event.monthstart, event.yearstart) }}</p>
        <p class="event-time">Время проведения: {{ formatTime(event.timestart, event.timeend) }}</p>
        <p class="event-location">Место проведения: {{ event.location }}</p>
        <p class="event-description">Описание: {{ event.description }}</p>
        <div v-if="showFullInfo">
          <p class="event-eventclass">Класс мероприятия: {{ event.eventclass }}</p>
          <p class="event-eventtype">Тип мероприятия: {{ eventAddType }}</p>
          <p class="event-eventsubtype">Подтип мероприятия: {{ eventSubType }}</p>
          <p class="event-organizator">Организатор: {{ eventOrganizator }}</p>
          <div class="event-members">
            <p>Соорганизаторы:</p>
            <ul>
              <li v-for="(suborganizator, index) in event.suborganizator" :key="index">◎ {{ suborganizator }}</li>
            </ul>
          </div>
          <p class="event-level">Уровень мероприятия: {{ event.level }}</p>
          <p class="event-responsible">Ответственный: {{ event.responsible }}</p>
          <div class="event-links">
            <p>Ссылки:</p>
            <ul>
              <li v-for="(link, index) in event.additionallinks" :key="index">
                ◎ <span v-html="createLink(link)"></span>
              </li>
            </ul>
          </div>
          <div class="event-members">
            <p>Целевая аудитория:</p>
            <ul>
              <li v-for="(member, index) in event.members" :key="index">◎ {{ member }}</li>
            </ul>
          </div>
        </div>
        <button @click="toggleFullInfo" class="toggle-info-button">
          {{ showFullInfo ? 'Скрыть доп. информацию' : 'Показать полную информацию' }}
          <span :class="['arrow', { 'up': showFullInfo, 'down': !showFullInfo }]"></span>
        </button>
      </div>
    </div>
    <div class="event-comments-container" id="comments" v-if="event">
      <div class="comments">
        <h2>Комментарии</h2>
        <form @submit.prevent="addComment" v-if="user">
          <textarea v-model="text" placeholder="Напишите комментарий..."></textarea>
          <button type="submit" class="form-btn">Добавить комментарий</button>
        </form>
        <div v-else>
          <p>Для добавления комментария необходимо <a href="/login">авторизоваться</a>.</p>
        </div>
        <div v-for="comment in localComments" :key="comment.id">
          <p>От {{ comment.userSurName }} {{ comment.userName }} ({{ comment.userEmail }})</p>
          <p class="comment-text">{{ comment.text }}</p>
        </div>
      </div>
    </div>
  </div>
  <modal :event="event" v-if="showModal" @close="showModal = false" />
  <!-- <customfooter /> -->
</template>

<script>
import navbar from "@/components/navbar.vue";
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import 'moment/locale/ru';
import modal from '@/components/ModalQR.vue';
import customfooter from "@/components/footer.vue";

export default {
  data() {
    return {
      text: '',
      showFullInfo: false,
      isLiked: false,
      showModal: false,
    };
  },
  mounted() {
    // Прокручиваем страницу вверх при монтировании компонента
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('comments', ['comments']),
    ...mapState('event', ['currentEvent']),
    ...mapState('references', ['organizators', 'addTypes', 'EventSubTypes']),
    event() {
      return this.currentEvent;
    },
    localComments() {
      return this.comments;
    },
    eventOrganizator() {
      return this.getOrganizatorName(this.event.organizator);
    },
    eventAddType() {
      return this.getAddTypeName(this.event.eventtype);
    },
    eventSubType() {
      return this.getEventSubTypeName(this.event.eventsubtype);
    },
    isEventPast() {
      if (this.event.datestart && this.event.timestart) {
        const eventDateTime = moment(`${this.event.datestart} ${this.event.timestart}`, 'YYYY-MM-DD HH:mm:ss');
        return eventDateTime.isBefore(moment());
      }
      return false; // Если дата или время отсутствуют, считаем, что мероприятие не прошло
    }
  },

  async created() {
    await this.loadEventData();
  },

  methods: {
    getImageSrc(imageUrl) {
      try {
        return require(`@/assets/posters/${imageUrl}`);
      } catch (error) {
        console.error(`Ошибка при загрузке изображения: ${imageUrl}`, error);
        return null;
      }
    },
    createLink(link) {
      // Проверяем, начинается ли ссылка с http:// или https://
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        link = 'https://' + link; // Добавляем протокол, если его нет
      }
      return `<a href="${link}" target="_blank">${link}</a>`;
    },
    formatDate(dateStart, dateEnd, monthStart, yearStart) {
      if (!dateStart && !dateEnd) {
        // Если даты нет, показываем месяц и год
        const monthNames = [
          "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
          "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];
        return `Дата проведения на уточнении (${monthNames[monthStart - 1]} ${yearStart})`;
      } else {
        // Если дата есть, форматируем её
        const startDate = moment(dateStart, 'YYYY-MM-DD');
        const endDate = moment(dateEnd, 'YYYY-MM-DD');
        return `${startDate.format('D MMMM YYYY')} - ${endDate.format('D MMMM YYYY')}`;
      }
    },
    formatTime(timeStart, timeEnd) {
      if (!timeStart && !timeEnd) {
        return "Время проведения на уточнении";
      } else {
        return `${moment(timeStart, 'HH:mm:ss').format('HH:mm')} - ${moment(timeEnd, 'HH:mm:ss').format('HH:mm')}`;
      }
    },
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
      fetchCommentsForEvent: 'comments/fetchCommentsForEvent',
      addCommentAction: 'comments/addComment',
      fetchEventDetails: 'event/fetchEventDetails',
      getorganizators: 'references/getorganizators',
      getaddtypes: 'references/getaddtypes',
      geteventsubtypes: 'references/geteventsubtypes',
      addToFavorites: 'user/addToFavorites',
    }),
    async addComment() {
      const comment = {
        text: this.text,
        eventuid: this.event.uid,
        useruid: this.user.uid,
        userName: this.user.name,
        userEmail: this.user.email,
        userSurName: this.user.surname,
      };
      await this.addCommentAction(comment);
      this.text = '';
      await this.fetchCommentsForEvent(this.event.uid);
    },
    getOrganizatorName(uid) {
      const organizator = this.organizators.find(org => org.uid === uid);
      return organizator ? organizator.name : uid;
    },
    getAddTypeName(uid) {
      const addType = this.addTypes.find(type => type.uid === uid);
      return addType ? addType.name : uid;
    },
    getEventSubTypeName(uid) {
      const subType = this.EventSubTypes.find(subtype => subtype.uid === uid);
      return subType ? subType.name : uid;
    },
    toggleFullInfo() {
      this.showFullInfo = !this.showFullInfo;
    },
    async loadEventData() {
      const eventId = this.$route.params.id;
      this.$store.commit('comments/CLEAR_COMMENTS'); // Очищаем комментарии перед загрузкой новых
      await this.fetchEventDetails(eventId);
      await this.getUserByUid();
      await this.fetchCommentsForEvent(eventId);

      // Получаем данные о организаторах, типах и подтипах
      await this.getorganizators();
      await this.getaddtypes();
      await this.geteventsubtypes();

      if (this.$route.hash === '#comments') {
        this.$nextTick(() => {
          const commentsSection = document.getElementById('comments');
          if (commentsSection) {
            commentsSection.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    },
    goToEventComments() {
      const commentsSection = document.getElementById('comments');
      if (commentsSection) {
        commentsSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    openModal() {
      this.showModal = true;
    },
    async toggleLike() {
      try {
        this.isLiked = !this.isLiked;
        await this.addToFavorites(this.event);
      } catch (error) {
        this.isLiked = !this.isLiked;
        console.error('Ошибка при добавлении/удалении из избранного:', error);
      }
    },
    generateQR() {
      // Логика для генерации QR-кода
      console.log('Генерация QR-кода для события:', this.event.uid);
    }
  },
  components: {
    navbar,
    modal,
    customfooter
  },
  beforeRouteLeave(to, from, next) {
    // Очищаем комментарии при выходе со страницы
    this.$store.commit('comments/CLEAR_COMMENTS');
    next();
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.loadEventData();
      }
    }
  }
};
</script>

<style scoped>
.event-image-container {
  display: flex;
  flex-direction: column;
}

.event-details-container {
  margin: 0 auto;
  padding-bottom: 30px;
  background-color: #99989814;
}

.event-comments-container {
  margin: 0 auto;
  width: 50%;
  border-radius: 20px;
  border: 1px solid #ef7d00;
  padding: 30px;
  background-color: #ffffff;
}

.event-details {
  text-align: left;
  flex-direction: column;
  padding: 30px;
  width: 50%;
  row-gap: 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #fff;
  margin: 30px;
}

.event-details h1 {
  text-align: center;
}

.backimage {
  width: 100%;
  min-height: auto;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.image-container {
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.event-image {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 768px) {
  .backimage {
    flex-direction: column;
    padding-bottom: 10px;
  }

  .image-container {
    width: 100%;
    margin: 30px 0;
  }

  .event-details {
    width: 91%;
    margin: 0;

  }

  .event-comments-container {
    width: 91%;
    padding: 15px;
  }

  .event-actions {
    flex-direction: column;
    align-items: center;
  }

  .like-button,
  .qr-button,
  .comment-button {
    margin: 5px 0;
  }
}

@media screen and (max-width: 600px) {
  .event-details-container {
    padding-bottom: 15px;
  }

  .event-details {
    padding: 15px;
  }

  .event-comments-container {
    padding: 15px;
  }

  .comments textarea {
    height: 80px;
  }

  .comments button {
    padding: 10px 20px;
  }
}

.comments {
  margin-top: 20px;
}

.comments h2 {
  margin-bottom: 10px;
}

.comments form {
  display: flex;
  flex-direction: column;
}

.comments textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  resize: none;
  height: 60px;
}

.comments div {
  margin-top: 5px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.comment-text {
  font-weight: bold;
  text-align: left;
  overflow-wrap: break-word;
  max-height: 200px;
  overflow-y: auto;
}

.comments button {
  background-color: #3c218c;
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.comments button:hover {
  background-color: #ef7d00;
}

.event-members ul,
.event-links ul {
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
}

.event-members li,
.event-links li {
  margin-bottom: 5px;
  text-indent: -15px;
  padding-left: 15px;
}

.toggle-info-button {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.toggle-info-button:hover {
  background-color: #ef7d00;
}

.arrow {
  margin-left: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow.up {
  transform: rotate(-135deg);
}

.arrow.down {
  transform: rotate(45deg);
}

.event-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.like-button,
.qr-button,
.comment-button {
  transition: color 0.3s ease, transform 0.3s ease;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px;
  color: #666;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.like-button:hover,
.qr-button:hover,
.comment-button:hover {
  transform: scale(1.1);
  color: #ef7d00;
}

.like-button.liked {
  color: #ef7d00;
}
</style>