<template>
  <div class="report-builder">
    <h2>Отчет по пользователям</h2>
    <div v-if="!selectedUser">
      <h3>Выберите пользователя:</h3>
      <table class="users-table">
        <thead>
          <tr>
            <th>UID</th>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Отчество</th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.uid">
            <td>{{ user.uid }}</td>
            <td>{{ user.surname }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.thirdname }}</td>
            <td><button @click="selectUser(user.uid)">Выбрать</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h3>Информация о пользователе "{{ selectedUser.surname }} {{ selectedUser.name }} ({{ selectedUser.email }})"</h3>
      <button class="back-btn" @click="resetUserSelection">
        <i class="fas fa-arrow-left"></i> Вернуться к выбору пользователя
      </button>

      <div v-if="!selectedReportType">
        <h4>Выберите тип отчета:</h4>
        <button @click="selectReportType('full')">Полный отчет</button>
        <button @click="selectReportType('info')">Отчет с информацией</button>
      </div>

      <div v-else>
        <button class="back-btn" @click="resetReportType">
          <i class="fas fa-arrow-left"></i> Вернуться к выбору типа отчета
        </button>

        <div v-if="selectedReportType === 'info'">
          <h4>Информация о пользователе "{{ selectedUser.surname }} {{ selectedUser.name }} ({{ selectedUser.email }})</h4>
          <div class="column-selection">
            <h5>Выберите столбцы для отображения:</h5>
            <button @click="selectAll('users')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in userColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.users" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <table class="info-table">
            <thead>
              <tr>
                <th>Атрибут</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="col in selectedColumns.users" :key="col">
                <td>{{ userColumns.find(c => c.key === col).label }}</td>
                <td>{{ selectedUser[col] }}</td>
              </tr>
            </tbody>
          </table>
          <button @click="exportToExcel">Скачать в Excel</button>
        </div>

        <div v-else-if="selectedReportType === 'full'" class="column-selection">
          <h4>Выберите столбцы для отчета:</h4>
          <!-- <div class="column-group">
            <h5>О мероприятии</h5>
            <button @click="selectAll('events')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in eventColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.events" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div> -->
          <div class="column-group">
            <h5>О пользователе</h5>
            <button @click="selectAll('users')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in userColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.users" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О регистрации</h5>
            <button @click="selectAll('registration')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in registrationColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.registration" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О присутствии</h5>
            <button @click="selectAll('attendance')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in attendanceColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.attendance" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
        </div>

        <div v-if="selectedReportType === 'full'">
          <h4>Отчет по пользователю "{{ selectedUser.name }} {{ selectedUser.surname }}"</h4>
          <button @click="exportToExcel">Скачать в Excel</button>
          <table class="full-report-table">
            <thead>
              <tr>
                <th v-for="col in selectedColumns.users" :key="col">{{ userColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.registration" :key="col">{{ registrationColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.attendance" :key="col">{{ attendanceColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.events" :key="col">{{ eventColumns.find(c => c.key === col).label }}</th>
              </tr>
            </thead>
            <tbody>
  <tr v-for="(registration, index) in combinedData" :key="index">
    <td v-for="col in selectedColumns.users" :key="col">{{ selectedUser[col] }}</td>
    <td v-for="col in selectedColumns.registration" :key="col">{{ registration[col] }}</td>
    <td v-for="col in selectedColumns.attendance" :key="col">{{ registration.attendance ? registration.attendance[col] : 'Нет данных' }}</td>
    <td v-for="col in selectedColumns.events" :key="col">{{ getEventColumnValue(col, registration.event) }}</td>
  </tr>
</tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  data() {
    return {
      selectedUser: null,
      selectedReportType: null,
      registrations: [],
      attendances: [],
      selectedColumns: {
        users: [],
        registration: [],
        attendance: [],
        events: []
      },
      eventColumns: [
        { key: 'uid', label: 'Уникальный идентификатор мероприятия' },
        { key: 'name', label: 'Название мероприятия' },
        { key: 'date', label: 'Дата мероприятия' },
        { key: 'timestart', label: 'Время начала мероприятия' },
        { key: 'timeend', label: 'Время окончания мероприятия' },
        { key: 'description', label: 'Описание мероприятия' },
        { key: 'imagename', label: 'Название файла с изображением мероприятия' },
        { key: 'organizator', label: 'Основной организатор мероприятия' },
        { key: 'suborganizator', label: 'Массив соорганизаторов мероприятия' },
        { key: 'location', label: 'Место проведения мероприятия' },
        { key: 'addtype', label: 'Тип добавления мероприятия' },
        { key: 'eventclass', label: 'Класс мероприятия' },
        { key: 'eventtype', label: 'Тип мероприятия' },
        { key: 'level', label: 'Уровень мероприятия' },
        { key: 'eventsubtype', label: 'Подтип мероприятия' },
        { key: 'additionallinks', label: 'Массив дополнительных ссылок' },
        { key: 'members', label: 'Целевая аудитория мероприятия' },
        { key: 'organizationform', label: 'Форма организации участия в мероприятии' },
        { key: 'responsible', label: 'Uid пользователя внесшего информацию о мероприятии' }
      ],
      userColumns: [
        { key: 'uid', label: 'Уникальный идентификатор пользователя' },
        { key: 'email', label: 'Электронная почта пользователя' },
        { key: 'name', label: 'Имя пользователя' },
        { key: 'surname', label: 'Фамилия пользователя' },
        { key: 'thirdname', label: 'Отчество пользователя' },
        { key: 'rolein', label: 'Роль пользователя в приложении' },
        { key: 'organization', label: 'Информация об организации пользователя' },
        { key: 'faculty', label: 'Информация о факультете пользователя' },
        { key: 'user_group', label: 'Информация об учебной группе пользователя' },
        { key: 'security', label: 'Информация об уровне доступа' },
        { key: 'country', label: 'Страна пользователя' }
      ],
      attendanceColumns: [
        { key: 'scanuidenter', label: 'UUID пользователя, сканирующего QR пользователя на вход' },
        { key: 'scanuidexit', label: 'UUID пользователя, сканирующего QR пользователя на выход' },
        { key: 'scannedenter', label: 'Временная метка, когда был отсканирован QR-код на вход' },
        { key: 'scannedexit', label: 'Временная метка, когда был отсканирован QR-код на выход' },
        { key: 'eventrole', label: 'Роль на мероприятии' }
      ],
      registrationColumns: [
        { key: 'activitytype', label: 'Тип активности' },
        { key: 'educationlevel', label: 'Уровень образования' },
        { key: 'course', label: 'Курс' },
        { key: 'studydirection', label: 'Направление обучения' },
        { key: 'otheractivitytype', label: 'Другой тип активности' },
        { key: 'country', label: 'Страна' },
        { key: 'feddistrict', label: 'Федеральный округ' },
        { key: 'region', label: 'Регион' },
        { key: 'city', label: 'Город' },
        { key: 'phone', label: 'Телефон' },
        { key: 'academicdegree', label: 'Ученая степень' },
        { key: 'academictitle', label: 'Ученое звание' },
        { key: 'organizationtype', label: 'Тип организации' },
        { key: 'customorganizationtype', label: 'Другой тип организации' },
        { key: 'organizationname', label: 'Название организации' },
        { key: 'participationform', label: 'Форма участия' },
        { key: 'reporttopic', label: 'Тема доклада' }
      ]
    };
  },
  computed: {
    ...mapState('user', ['users']),
    ...mapState('eventregistration', ['registrations']),
    ...mapState('attendance', ['attendances']),
    ...mapState('event', ['events']),
    ...mapState('references', [
    'organizators',
    'addTypes',
    'EventClasses',
    'EventTypes',
    'Levels',
    'EventSubTypes',
    'Members',
    'OrganizationForms'
  ]),
  combinedData() {
      return this.registrations.map(registration => {
        const attendance = this.attendances.find(att => att.useruid === registration.userid);
        const event = this.events.find(event => event.uid === registration.eventuid);
        return { ...registration, attendance, event };
      });
    }
  },
  methods: {
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('eventregistration', ['getEventRegistrationsByUserUid']),
    ...mapActions('attendance', ['getAttendanceByUserUid']),
    // ...mapActions('event', ['getEventsByUserUid']),
    ...mapActions('event', ['getEventByUidForReport']),
    async loadUsers() {
      
      try {
        await this.getAllUsers();
        await this.getallevents();
        await Promise.all([
          this.getorganizators(),
          this.getaddtypes(),
          this.geteventclasses(),
          this.geteventtypes(),
          this.getlevels(),
          this.geteventsubtypes(),
          this.getmembers(),
          this.getorganizationforms()
        ]);
      } catch (error) {
        console.error('Ошибка при загрузке пользователей:', error);
      }
    },
    async selectUser(userUid) {
      try {
        this.selectedUser = this.users.find(user => user.uid === userUid);
        this.registrations = await this.getEventRegistrationsByUserUid(userUid);
        this.attendances = await this.getAttendanceByUserUid(userUid);

        // Загружаем данные о мероприятии для каждого события, связанного с пользователем
        const eventUids = this.registrations.map(reg => reg.eventuid);
        const events = await Promise.all(eventUids.map(eventUid => this.getEventByUidForReport(eventUid)));
        this.events = events.filter(event => event); // Убедитесь, что все события загружены

        // console.log('Загруженные данные о мероприятиях:', this.events);
      } catch (error) {
        console.error('Ошибка при загрузке информации о пользователе:', error);
      }
    },
    resetUserSelection() {
      this.selectedUser = null;
      this.selectedReportType = null;
      this.registrations = [];
      this.attendances = [];
      this.events = [];
    },
    selectReportType(type) {
      this.selectedReportType = type;
    },
    resetReportType() {
      this.selectedReportType = null;
    },
    selectAll(group) {
      if (group === 'users') {
        if (this.selectedColumns.users.length === this.userColumns.length) {
          this.selectedColumns.users = [];
        } else {
          this.selectedColumns.users = this.userColumns.map(col => col.key);
        }
      } else if (group === 'registration') {
        if (this.selectedColumns.registration.length === this.registrationColumns.length) {
          this.selectedColumns.registration = [];
        } else {
          this.selectedColumns.registration = this.registrationColumns.map(col => col.key);
        }
      } else if (group === 'attendance') {
        if (this.selectedColumns.attendance.length === this.attendanceColumns.length) {
          this.selectedColumns.attendance = [];
        } else {
          this.selectedColumns.attendance = this.attendanceColumns.map(col => col.key);
        }
      } else if (group === 'events') {
        if (this.selectedColumns.events.length === this.eventColumns.length) {
          this.selectedColumns.events = [];
        } else {
          this.selectedColumns.events = this.eventColumns.map(col => col.key);
        }
      }
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(time) {
      if (!time) return '';
      const t = new Date(time);
      const hours = String(t.getHours()).padStart(2, '0');
      const minutes = String(t.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const dt = new Date(dateTime);
      const day = String(dt.getDate()).padStart(2, '0');
      const month = String(dt.getMonth() + 1).padStart(2, '0');
      const year = dt.getFullYear();
      const hours = String(dt.getHours()).padStart(2, '0');
      const minutes = String(dt.getMinutes()).padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    getEventColumnValue(col, event) {
  if (!event) return '';
  switch (col) {
    case 'date':
      return this.formatDate(event[col]);
    case 'timestart':
    case 'timeend':
      return this.formatTime(event[col]);
    default:
      return event[col] || ''; // Добавляем проверку на null или undefined
  }
},
exportToExcel() {
  const ws = XLSX.utils.json_to_sheet(this.combinedData.map(row => {
    const rowData = {};
    this.selectedColumns.users.forEach(col => {
      rowData[this.userColumns.find(c => c.key === col).label] = this.selectedUser[col];
    });
    this.selectedColumns.registration.forEach(col => {
      rowData[this.registrationColumns.find(c => c.key === col).label] = row[col];
    });
    this.selectedColumns.attendance.forEach(col => {
      rowData[this.attendanceColumns.find(c => c.key === col).label] = row.attendance ? row.attendance[col] : 'Нет данных';
    });
    this.selectedColumns.events.forEach(col => {
      rowData[this.eventColumns.find(c => c.key === col).label] = this.getEventColumnValue(col, row.event);
    });
    return rowData;
  }));

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Отчет');
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `Отчет_${this.selectedUser.name}_${this.selectedUser.surname}.xlsx`;
  link.click();
  URL.revokeObjectURL(url);
}
  },
  created() {
    this.loadUsers();
  }
}
</script>

<style scoped>
.report-builder {
  margin-top: 20px;
}

h2, h3 {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

button {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

.back-btn {
  margin-bottom: 20px;
  background-color: #dc3545;
}

.back-btn:hover {
  background-color: #c82333;
}

.column-selection {
  margin-bottom: 20px;
}

.column-group {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.checkbox-grid label {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>