<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <div class="header">Добавление мероприятия</div>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div class="home">
              <form @submit.prevent="goaddEvent()" class="event-form">
                <!-- Основные поля -->
                <label for="eventName">Название</label>
                <input type="text" id="eventName" name="name" class="form-input" placeholder="Название" required
                  v-model="eventName">
                <span v-if="errors.name" class="error">{{ errors.name }}</span>

                <!-- Переключатель для выбора режима -->
                <label>
                  <input type="checkbox" v-model="isDateUnknown" @change="toggleDateInputs" />
                  Точная дата мероприятия неизвестна
                </label>

                <!-- Поля для точной даты и времени -->
                <div v-if="!isDateUnknown">
                  <label for="eventDateStart">Дата начала</label>
                  <input type="date" id="eventDateStart" name="datestart" class="form-input" placeholder="Дата начала"
                    v-model="eventDateStart">
                  <span v-if="errors.datestart" class="error">{{ errors.datestart }}</span>

                  <label for="eventDateEnd">Дата окончания</label>
                  <input type="date" id="eventDateEnd" name="dateend" class="form-input" placeholder="Дата окончания"
                    v-model="eventDateEnd">
                  <span v-if="errors.dateend" class="error">{{ errors.dateend }}</span>

                  <label>
                    <input type="checkbox" v-model="isTimeUnknown" @change="toggleTimeInputs" />
                    Точное время мероприятия неизвестно
                  </label>

                  <div v-if="!isTimeUnknown">
                    <label for="eventTimeStart">Время начала</label>
                    <input type="time" id="eventTimeStart" name="timestart" class="form-input" placeholder="Время начала"
                      v-model="eventTimeStart">
                    <span v-if="errors.timestart" class="error">{{ errors.timestart }}</span>

                    <label for="eventTimeEnd">Время окончания</label>
                    <input type="time" id="eventTimeEnd" name="timeend" class="form-input" placeholder="Время окончания"
                      v-model="eventTimeEnd">
                    <span v-if="errors.timeend" class="error">{{ errors.timeend }}</span>
                  </div>
                </div>

                <!-- Поля для месяца и года -->
                <div v-else>
                  <label for="eventMonthStart">Месяц начала</label>
                  <select id="eventMonthStart" name="monthstart" class="form-input" v-model="eventMonthStart">
                    <option value="">Выберите месяц</option>
                    <option v-for="month in months" :key="month.value" :value="month.value">{{ month.label }}</option>
                  </select>
                  <span v-if="errors.monthstart" class="error">{{ errors.monthstart }}</span>

                  <label for="eventYearStart">Год</label>
                  <input type="number" id="eventYearStart" name="yearstart" class="form-input" placeholder="Год начала"
                    v-model="eventYearStart">
                  <span v-if="errors.yearstart" class="error">{{ errors.yearstart }}</span>
                </div>

                <!-- Остальные поля формы -->
                <label for="eventDescription">Описание</label>
                <input type="text" id="eventDescription" name="description" class="form-input" placeholder="Описание"
                  required v-model="eventDescription">
                <span v-if="errors.description" class="error">{{ errors.description }}</span>

                <label for="eventImageName">Название изображения</label>
                <input type="text" id="eventImageName" name="imageName" class="form-input"
                  placeholder="Название изображения" v-model="eventImageName">

                <!-- Организаторы -->
                <label for="eventOrganizator">Организатор</label>
                <select id="eventOrganizator" name="organizator" class="form-input" required v-model="eventOrganizator">
                  <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                </select>
                <span v-if="errors.organizator" class="error">{{ errors.organizator }}</span>

                <label for="eventSuborganizator">Соорганизатор (-ы)</label>
                <div v-for="(suborg, index) in eventSuborganizator" :key="index" class="suborg-input">
                  <select class="form-input" v-model="eventSuborganizator[index]">
                    <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                  </select>
                  <button type="button" @click="removeSuborganizator(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addSuborganizator" class="btn-add">Добавить соорганизатора</button>

                <!-- Место проведения -->
                <label for="eventLocation">Место проведения</label>
                <input type="text" id="eventLocation" name="location" class="form-input" placeholder="Место проведения"
                  required v-model="eventLocation">
                <span v-if="errors.location" class="error">{{ errors.location }}</span>

                <!-- Класс, тип, уровень, подтип -->
                <label for="eventClass">Класс мероприятия</label>
                <select id="eventClass" name="eventClass" class="form-input" required v-model="eventClass">
                  <option v-for="cls in EventClasses" :key="cls.uid" :value="cls.uid">{{ cls.name }}</option>
                </select>
                <span v-if="errors.eventclass" class="error">{{ errors.eventclass }}</span>

                <label for="eventType">Тип мероприятия</label>
                <select id="eventType" name="eventType" class="form-input" required v-model="eventType">
                  <option v-for="type in EventTypes" :key="type.uid" :value="type.uid">{{ type.name }}</option>
                </select>
                <span v-if="errors.eventtype" class="error">{{ errors.eventtype }}</span>

                <label for="eventLevel">Уровень</label>
                <select id="eventLevel" name="level" class="form-input" required v-model="eventLevel">
                  <option v-for="lvl in Levels" :key="lvl.uid" :value="lvl.uid">{{ lvl.name }}</option>
                </select>
                <span v-if="errors.level" class="error">{{ errors.level }}</span>

                <label for="eventSubType">Подтип мероприятия</label>
                <select id="eventSubType" name="eventSubType" class="form-input" required v-model="eventSubType">
                  <option v-for="subType in EventSubTypes" :key="subType.uid" :value="subType.uid">{{ subType.name }}
                  </option>
                </select>
                <span v-if="errors.eventsubtype" class="error">{{ errors.eventsubtype }}</span>

                <!-- Участники -->
                <label for="eventMember">Участники</label>
                <div v-for="(member, index) in eventMember" :key="index" class="member-input">
                  <select class="form-input" v-model="eventMember[index]">
                    <option v-for="mem in Members" :key="mem.uid" :value="mem.uid">{{ mem.name }}</option>
                  </select>
                  <button type="button" @click="removeMember(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addMember" class="btn-add">Добавить участника</button>

                <!-- Форма организации -->
                <label for="eventOrganizationForm">Форма организации</label>
                <select id="eventOrganizationForm" name="organizationForm" class="form-input" required
                  v-model="eventOrganizationForm">
                  <option v-for="form in OrganizationForms" :key="form.uid" :value="form.uid">{{ form.name }}</option>
                </select>
                <span v-if="errors.organizationform" class="error">{{ errors.organizationform }}</span>

                <!-- Ответственный -->
                <label for="eventResponsible">ФИО ответственного (-ых)</label>
                <input type="text" id="eventResponsible" name="responsible" class="form-input"
                  placeholder="Ответственный" required v-model="eventResponsible">
                <span v-if="errors.responsible" class="error">{{ errors.responsible }}</span>

                <!-- Email организаторов -->
                <label for="eventOrganizerEmails">Email организаторов</label>
                <div v-for="(email, index) in responsiblelogin" :key="index" class="email-input">
                  <input type="email" class="form-input" placeholder="Email организатора"
                    v-model="responsiblelogin[index]" required>
                  <button type="button" @click="removeOrganizerEmail(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addOrganizerEmail" class="btn-add">Добавить email организатора</button>
                <p class="note">Все email, которые вы добавите, будут иметь доступ к управлению мероприятием!</p>

                <!-- Дополнительные ссылки -->
                <label for="eventAdditionalLinks">Дополнительные ссылки</label>
                <div v-for="(link, index) in eventAdditionalLinks" :key="index" class="link-input">
                  <input type="text" class="form-input" placeholder="Ссылка" v-model="eventAdditionalLinks[index]">
                  <button type="button" @click="removeLink(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addLink" class="btn-add">Добавить ссылку</button>

                <!-- Секции -->
                <label for="eventSections">Секции</label>
                <div v-for="(section, index) in eventSections" :key="index" class="section-input">
                  <input type="text" class="form-input" placeholder="Секция" v-model="eventSections[index]">
                  <button type="button" @click="removeSection(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addSection" class="btn-add">Добавить секцию</button>

                <!-- Кнопка отправки -->
                <button type="submit" class="form-btn2">Добавить мероприятие</button>
              </form>

              <!-- Загрузка из файла -->
              <h3>Или загрузите файл, содержащий таблицу, которая заполнена по шаблону</h3>
              <input type="file" @change="handleFileUpload" class="file-input">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer">
            <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <!-- Индикатор загрузки -->
  <transition name="fade">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <p>Идет загрузка данных...</p>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as XLSX from 'xlsx';
import moment from 'moment';

export default {
  name: 'ModalAddEvent',
  data() {
    return {
      isDateUnknown: false,
      isTimeUnknown: false,
      eventName: '',
      eventDateStart: '',
      eventDateEnd: '',
      eventTimeStart: '',
      eventTimeEnd: '',
      eventMonthStart: '',
      eventYearStart: '',
      eventDescription: '',
      eventImageName: 'none.jpg', // Значение по умолчанию
      eventOrganizator: '00', // По умолчанию "Не определено"
      eventSuborganizator: ['00'],
      eventLocation: 'Не определено', // По умолчанию "Не определено"
      eventClass: '00', // По умолчанию "Не определено"
      eventType: '00', // По умолчанию "Не определено"
      eventLevel: '00', // По умолчанию "Не определено"
      eventSubType: '00', // По умолчанию "Не определено"
      eventMember: ['00'],
      eventAdditionalLinks: [],
      eventOrganizationForm: '00', // По умолчанию "Не определено"
      eventResponsible: 'Не определено', // По умолчанию "Не определено"
      responsiblelogin: [],
      eventSections: [],
      loading: false,
      months: [
        { value: 1, label: 'Январь' },
        { value: 2, label: 'Февраль' },
        { value: 3, label: 'Март' },
        { value: 4, label: 'Апрель' },
        { value: 5, label: 'Май' },
        { value: 6, label: 'Июнь' },
        { value: 7, label: 'Июль' },
        { value: 8, label: 'Август' },
        { value: 9, label: 'Сентябрь' },
        { value: 10, label: 'Октябрь' },
        { value: 11, label: 'Ноябрь' },
        { value: 12, label: 'Декабрь' },
      ],
      errors: {}, // Для хранения ошибок валидации
    };
  },
  computed: {
    ...mapState('references', [
      'organizators',
      'addTypes',
      'EventClasses',
      'EventTypes',
      'Levels',
      'EventSubTypes',
      'Members',
      'OrganizationForms',
    ]),
    ...mapState('user', ['user']), // Получаем текущего пользователя
  },
  methods: {
    toggleDateInputs() {
      // Очищаем поля при переключении режима
      if (this.isDateUnknown) {
        this.eventDateStart = '';
        this.eventDateEnd = '';
        this.eventTimeStart = '';
        this.eventTimeEnd = '';
      } else {
        this.eventMonthStart = '';
        this.eventYearStart = '';
      }
    },
    toggleTimeInputs() {
      // Очищаем поля времени при переключении режима
      if (this.isTimeUnknown) {
        this.eventTimeStart = '';
        this.eventTimeEnd = '';
      }
    },
    closeModal() {
      this.$emit('close');
    },
    ...mapActions('references', [
      'getorganizators',
      'getaddtypes',
      'geteventclasses',
      'geteventtypes',
      'getlevels',
      'geteventsubtypes',
      'getmembers',
      'getorganizationforms',
    ]),
    ...mapActions('event', ['addevent', 'getallevents']),

    async goaddEvent() {
      this.errors = {}; // Очищаем ошибки перед валидацией
      if (this.isDateUnknown) {
        if (!this.eventMonthStart) this.errors.monthstart = 'Укажите месяц';
        if (!this.eventYearStart) this.errors.yearstart = 'Укажите год';
      } else {
        if (!this.eventDateStart) this.errors.datestart = 'Укажите дату начала';
        if (!this.eventDateEnd) this.errors.dateend = 'Укажите дату окончания';
        if (!this.isTimeUnknown) {
          if (!this.eventTimeStart) this.errors.timestart = 'Укажите время начала';
          if (!this.eventTimeEnd) this.errors.timeend = 'Укажите время окончания';
        }
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }
      // Валидация обязательных полей
      if (!this.eventName) this.errors.name = 'Название обязательно';
      if (!this.eventDateStart && !this.isDateUnknown) this.errors.datestart = 'Дата начала обязательна';
      if (!this.eventDateEnd && !this.isDateUnknown) this.errors.dateend = 'Дата окончания обязательна';
      if (!this.eventDescription) this.errors.description = 'Описание обязательно';

      // Если есть ошибки, останавливаем отправку
      if (Object.keys(this.errors).length > 0) {
        return;
      }

      // Добавляем email текущего пользователя в начало массива responsiblelogin
      const userEmail = this.user.email;
      const responsiblelogin = [userEmail, ...this.responsiblelogin.filter((email) => email)];

      const formData = {
        name: this.eventName,
        datestart: this.isDateUnknown ? null : this.eventDateStart,
        dateend: this.isDateUnknown ? null : this.eventDateEnd,
        timestart: this.isDateUnknown || this.isTimeUnknown ? null : this.eventTimeStart,
        timeend: this.isDateUnknown || this.isTimeUnknown ? null : this.eventTimeEnd,
        monthstart: this.isDateUnknown ? this.eventMonthStart : null,
        yearstart: this.isDateUnknown ? this.eventYearStart : null,
        description: this.eventDescription,
        imagename: this.eventImageName,
        organizator: this.eventOrganizator || '00',
        suborganizator: this.eventSuborganizator.filter((suborg) => suborg),
        location: this.eventLocation || 'Не определено',
        eventclass: this.eventClass || '00',
        eventtype: this.eventType || '00',
        level: this.eventLevel || '00',
        eventsubtype: this.eventSubType || '00',
        additionallinks: this.eventAdditionalLinks.filter((link) => link),
        members: this.eventMember.filter((member) => member),
        organizationform: this.eventOrganizationForm || '00',
        responsible: this.eventResponsible || 'Не определено',
        responsiblelogin: responsiblelogin, // Используем обновленный массив
        sections: this.eventSections.filter((section) => section),
        addtype: '01', // Значение по умолчанию
        createdby: userEmail, // Используем email текущего пользователя
        isactive: true, // Значение по умолчанию
      };

      try {
        await this.addevent(formData);
        window.alert('Мероприятие добавлено');
        this.getallevents();
      } catch (error) {
        console.error('Ошибка при добавлении мероприятия:', error);
      }
    },

    addLink() {
      this.eventAdditionalLinks.push('');
    },
    removeLink(index) {
      this.eventAdditionalLinks.splice(index, 1);
    },
    addSuborganizator() {
      this.eventSuborganizator.push('');
    },
    removeSuborganizator(index) {
      this.eventSuborganizator.splice(index, 1);
    },
    addMember() {
      this.eventMember.push('');
    },
    removeMember(index) {
      this.eventMember.splice(index, 1);
    },
    addOrganizerEmail() {
      this.responsiblelogin.push('');
    },
    removeOrganizerEmail(index) {
      this.responsiblelogin.splice(index, 1);
    },
    addSection() {
      this.eventSections.push('');
    },
    removeSection(index) {
      this.eventSections.splice(index, 1);
    },

    async handleFileUpload(event) {
  const file = event.target.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = async (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);

    for (const row of jsonData) {
      const userEmail = this.user.email;
      const responsiblelogin = [userEmail, ...this.parseOrganizerEmails(row['Email организаторов'])];

      // Обработка дат
      const datestart = row['Дата начала'] ? moment(row['Дата начала'], 'DD.MM.YY').format('YYYY-MM-DD') : null;
      const dateend = row['Дата окончания'] ? moment(row['Дата окончания'], 'DD.MM.YY').format('YYYY-MM-DD') : null;

      // Обработка времени
      const timestart = row['Время начала'] || null;
      const timeend = row['Время окончания'] || null;

      // Обработка месяца и года
      const monthstart = row['Месяц мероприятия'] || null;
      const yearstart = row['Год мероприятия'] || null;

      const eventData = {
        name: row['Название'] || 'Не определено',
        datestart: datestart,
        dateend: dateend,
        timestart: timestart,
        timeend: timeend,
        monthstart: monthstart,
        yearstart: yearstart,
        description: row['Описание'] || 'Не определено',
        imagename: row['Название изображения'] || 'none.jpg',
        organizator: this.findOrganizator(row['Организатор']) || '00',
        suborganizator: this.parseSuborganizators(row['Соорганизатор (-ы)']) || [],
        location: row['Место проведения'] || 'Не определено',
        eventclass: this.findEventClass(row['Класс мероприятия']) || '00',
        eventtype: this.findEventType(row['Тип мероприятия']) || '00',
        level: this.findEventLevel(row['Уровень']) || '00',
        eventsubtype: this.findEventSubType(row['Подтип мероприятия']) || '00',
        additionallinks: this.parseAdditionalLinks(row['Дополнительные ссылки']) || [],
        members: this.parseMembers(row['Целевая аудитория']) || [],
        organizationform: this.findOrganizationForm(row['Форма организации']) || '00',
        responsible: row['ФИО ответственного (-ых)'] || 'Не определено',
        responsiblelogin: responsiblelogin,
        sections: this.parseSections(row['Секции']) || [],
        addtype: '02', // Значение по умолчанию
        createdby: userEmail,
        isactive: true,
      };

      try {
        await this.addevent(eventData);
      } catch (error) {
        console.error('Ошибка при добавлении мероприятия:', error);
      }
    }

    window.alert('Мероприятия добавлены');
    this.getallevents();
  };
  reader.readAsArrayBuffer(file);
},

    findOrganizator(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const organizator = this.organizators.find((org) => org.fullname === formattedName);
      return organizator ? organizator.uid : '00';
    },
    findEventClass(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventClass = this.EventClasses.find((cls) => cls.name === formattedName);
      return eventClass ? eventClass.uid : '00';
    },
    findEventType(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventType = this.EventTypes.find((type) => type.name === formattedName);
      return eventType ? eventType.uid : '00';
    },
    findEventLevel(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventLevel = this.Levels.find((lvl) => lvl.name === formattedName);
      return eventLevel ? eventLevel.uid : '00';
    },
    findEventSubType(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventSubType = this.EventSubTypes.find((subType) => subType.name === formattedName);
      return eventSubType ? eventSubType.uid : '00';
    },
    findOrganizationForm(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const organizationForm = this.OrganizationForms.find((form) => form.name === formattedName);
      return organizationForm ? organizationForm.uid : '00';
    },
    parseAdditionalLinks(links) {
      if (!links) return [];
      return links.split(',').map((link) => link.trim());
    },
    parseSuborganizators(suborganizators) {
      if (!suborganizators) return [];
      return suborganizators.split(',').map((name) => {
        const formattedName = name.trim().charAt(0).toUpperCase() + name.trim().slice(1);
        const organizator = this.organizators.find((org) => org.fullname === formattedName);
        return organizator ? organizator.uid : '00';
      });
    },
    parseMembers(members) {
      if (!members) return [];
      return members.split(',').map((name) => {
        const formattedName = name.trim().charAt(0).toUpperCase() + name.trim().slice(1);
        const member = this.Members.find((mem) => mem.name === formattedName);
        return member ? member.uid : '00';
      });
    },
    parseOrganizerEmails(emails) {
      if (!emails) return [];
      return emails.split(',').map((email) => email.trim());
    },
    parseSections(sections) {
      if (!sections) return [];
      return sections.split(',').map((section) => section.trim());
    },
    getAddType(method) {
      const addType = this.addTypes.find((type) => type.name === method);
      return addType ? addType.uid : '00';
    },
  },
  async created() {
    this.loading = true;
    await Promise.all([
      this.getorganizators(),
      this.getaddtypes(),
      this.geteventclasses(),
      this.geteventtypes(),
      this.getlevels(),
      this.geteventsubtypes(),
      this.getmembers(),
      this.getorganizationforms(),
    ]);
    this.loading = false;
  },
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.home {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.event-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.form-btn2 {
  padding: 10px;
  background-color: #ef7d00;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.form-btn2:hover {
  background-color: #3c218c;
}

.form-btn {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.form-btn:hover {
  background-color: #ef7d00;
}

.file-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f8f8f8;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.file-input::-webkit-file-upload-button {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.file-input::-webkit-file-upload-button:hover {
  background-color: #3c218c;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Убедитесь, что это значение выше, чем у других элементов */
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  overflow-y: auto; /* Прокрутка контента внутри модального окна */
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
  /* Это значение должно быть выше, чем у backdrop */
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  cursor: pointer;
}

.btn-add,
.btn-remove {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
}

.btn-add:hover,
.btn-remove:hover {
  background-color: #ef7d00;
}

/* .btn-add::after {
  content: " ссылки";
} */
</style>