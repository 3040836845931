<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" @click.self="closeModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
                @click.stop>
                <div class="modal-header" id="modalTitle">
                    <div class="header">
                        Ваши данные:
                    </div>
                </div>
                <div class="modal-body" id="modalDescription">
                    <div class="body">
                        <!-- <div class="info-row">
                            <i class="fas fa-envelope"></i>
                            <span class="label">Email:</span>
                            <input type="email" class="form-input" placeholder="Введите email" required minlength="5"
                                maxlength="40" v-model="email">
                        </div> -->
                        <div class="info-row">
                            <i class="fas fa-user"></i>
                            <span class="label">Фамилия:</span>
                            <input type="text" class="form-input" placeholder="Введите фамилию" required
                                v-model="surname">
                        </div>
                        <div class="info-row">
                            <i class="fas fa-user"></i>
                            <span class="label">Имя:</span>
                            <input type="text" class="form-input" placeholder="Введите имя" required v-model="name">
                        </div>
                        <div class="info-row">
                            <i class="fas fa-user"></i>
                            <span class="label">Отчество:</span>
                            <input type="text" class="form-input" placeholder="Введите отчество" required
                                v-model="thirdname">
                        </div>
                        <div class="info-row">
                            <i class="fas fa-university"></i>
                            <span class="label">Организация:</span>
                            <input type="text" class="form-input"
                                placeholder="Организация - аббревиатура (если есть)" v-model="organization">
                        </div>
                        <div class="info-row">
                            <i class="fas fa-user-tag"></i>
                            <span class="label">Роль в учебном заведении:</span>
                            <select class="form-input" required v-model="rolein">
                                <option value="" disabled selected>Роль (ученик, студент, преподаватель, гость)</option>
                                <option value="Ученик">Ученик</option>
                                <option value="Студент">Студент</option>
                                <option value="Преподаватель">Преподаватель</option>
                                <option value="Учитель">Учитель</option>
                                <option value="Гость">Гость</option>
                            </select>
                        </div>
                        <div class="info-row">
                            <i class="fas fa-users"></i>
                            <span class="label">Факультет:</span>
                            <input type="text" class="form-input" placeholder="Факультет (если есть)" v-model="faculty">
                        </div>
                        <div class="info-row">
                            <i class="fas fa-users"></i>
                            <span class="label">Группа:</span>
                            <input type="text" class="form-input" placeholder="Группа или класс (если есть)" v-model="group">
                        </div>
                        <button class="form-btn" @click="updateUser">Сохранить</button>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="footer">
                        <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'EditUserDataModal',
    data() {
        return {
            email: '',
            surname: '',
            name: '',
            thirdname: '',
            organization: '',
            rolein: '',
            faculty: '',
            group: ''
        };
    },
    async created() {
        await this.getUserByUid();
        this.setUserData();
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async updateUser() {
    const userData = {
      uid: this.user.uid,
      email: this.email,
      surname: this.surname,
      name: this.name,
      thirdname: this.thirdname,
      organization: this.organization,
      rolein: this.rolein,
      faculty: this.faculty,
      user_group: this.group
    };

    try {
      const updatedUser = await this.updateUserInStore(userData);
      // console.log('Ответ сервера:', updatedUser);

      // Обновляем данные пользователя в сторе
      await this.getUserByUid();

      // Закрываем модальное окно
      this.closeModal();

      alert('Ваши данные обновлены');
    } catch (error) {
      console.error('Ошибка при обновлении пользователя:', error);
    }
  },
        setUserData() {
            this.email = this.user.email || '';
            this.surname = this.user.surname || '';
            this.name = this.user.name || '';
            this.thirdname = this.user.thirdname || '';
            this.organization = this.user.organization || '';
            this.rolein = this.user.rolein || '';
            this.faculty = this.user.faculty || '';
            this.group = this.user.user_group || '';
        },
        ...mapActions({
            getUserByUid: 'user/getUserByUid',
            updateUserInStore: 'user/updateUser'
        })
    },
    computed: {
        ...mapState('user', ['user'])
    }
};
</script>
<style scoped>
.info-row {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Отступ между элементами внутри info-row */
    padding: 10px;
}
.comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.comment-text {
    flex-grow: 1;
}

.comment-date {
    font-size: 0.8em;
    color: #999;
    margin-right: 10px;
}

.btn-red {
    color: white;
    background: #ff4d4f;
    border: 1px solid #eeeeee;
    border-radius: 20px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 80%; /* Изменено */
  max-width: 800px; /* Изменено */
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-green {
    color: white;
    background: #3c218c;
    border: 1px solid #eeeeee;
    border-radius: 20px;
}
form {
    display: flex;
  padding: 20px;
  flex-direction: column;
  width: 400px;
  row-gap: 20px;
  border: 1px solid #ef7d00; /* Added border with orange color */
  border-radius: 20px;
  background-color: #fff; /* Set form background to white */
  margin-bottom: 100px;
}
.content {
  margin-top: 70px;
  text-align: center;
}

h2 {
  color: #000000;
  font-size: 2em;
}

p {
  color: #000000;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-input {
    padding: 15px; /* Increased padding for better readability */
  margin: 0;
  border: none; /* Removed border for input fields */
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5; /* Set input background to light gray */
}

.form-btn {
    background-color: #ef7d00; /* Button background to orange */
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto; /* Center the button horizontally */
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c;
}

a {
  color: #3c218c;
  text-decoration: none;
}

a:hover {
  color: #ef7d00;
}
</style>