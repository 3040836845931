<template>
    <div>
      <navbar />
      <div class="content">
        <div class="under-construction-message">
          <h2>Страница "Выступления" находится в разработке</h2>
          <p>Пожалуйста, загляните сюда позднее.</p>
        </div>
      </div>
      <!-- <customfooter /> -->
    </div>
  </template>
  
  <script>
  import navbar from "@/components/navbar.vue";
  // import customfooter from "@/components/footer.vue";
  
  export default {
    name: 'PerformancesView',
    components: {
      navbar,
      // customfooter,
    },
    mounted() {
    window.scrollTo(0, 0);
  },
  }
  </script>
  
  <style scoped>
  .content {
    margin-top: 70px; /* Отступ сверху для основного контента */
  }
  
  .under-construction-message {
    text-align: center;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .under-construction-message h2 {
    color: #3c218c;
    margin-bottom: 10px;
  }
  
  .under-construction-message p {
    color: #666;
    margin-bottom: 20px;
  }
  </style>