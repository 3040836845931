import { createRouter, createWebHistory } from 'vue-router'
import EventsView from '../views/EventsView.vue'
import AboutView from '../views/AboutView.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import DataAddforMS from '../views/DataAddforMS.vue'
import Favorite from '../views/FavoriteEventsPage.vue'
import EventDetails from '../components/EventDetails.vue'; 
import ReportsView from '@/views/ReportsView.vue';
import MyEventsView from '@/views/MyEvents.vue';
import RegistrationReport from '@/views/RegistrationReport.vue';
import AttendanceReport from '@/views/AttendanceReport.vue';
import LoginCallback from '@/views/LoginCallback.vue';
import RegisterHistory from '@/views/RegisterHistory.vue'
import VisitHistory from '@/views/VisitHistory.vue'
import Cerst from '@/views/Cerst.vue'
import Performances from '@/views/Performances.vue'
const routes = [
  {
    path: '/user-certs',
    name: 'Cerst',
    component: Cerst,
  },
  {
    path: '/user-performances',
    name: 'Performances',
    component: Performances,
  },
  {
    path: '/registration-history',
    name: 'RegisterHistory',
    component: RegisterHistory,
  },
  {
    path: '/visit-history',
    name: 'VisitHistory',
    component: VisitHistory,
  },
  {
    path: '/',
    redirect: '/login',
    component: Login
  },
  {
    path: '/login',
    name: 'loginpage',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/EventsView',
    name: 'EventsView',
    component: EventsView
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/login-callback', // Маршрут для обработки кода авторизации
    name: 'LoginCallback',
    component: LoginCallback,
  },
  // {
  //   path: '/DataAddforMS',
  //   name: 'DataAddforMS',
  //   component: DataAddforMS
  // },
  {
    path: '/event/:id',
    name: 'EventDetails',
    component: EventDetails,
    props: true
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: Favorite,
  },
  {
    path: '/report',
    name: 'Report',
    component: ReportsView
  },
  {
    path: '/myevents',
    name: 'myevents',
    component: MyEventsView
  },
  {
    path: '/registration-report/:eventId',
    name: 'RegistrationReport',
    component: RegistrationReport,
    props: true
  },
  {
    path: '/attendance-report/:eventId',
    name: 'AttendanceReport',
    component: AttendanceReport,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router