<template>
  <navbar />
  <div class="content">
    <h1>Создание отчетов</h1>
    <div class="report-buttons">
      <div class="report-section">
        <button class="report-btn" @click="openReport('events')">
          <i class="fas fa-calendar-alt"></i> Отчеты по мероприятиям
        </button>
      </div>
      <div class="report-section">
        <button class="report-btn" @click="openReport('students')">
          <i class="fas fa-user-graduate"></i> Отчеты по пользователям
        </button>
      </div>
      </div>
    <div v-if="selectedReport === 'events'">
      <EventsReport />
    </div>
    <div v-else-if="selectedReport === 'students'">
      <StudentsReport />
    </div>
  </div>
</template>

<script>
import navbar from "@/components/navbar.vue";
import EventsReport from "@/components/EventsReport.vue";
import StudentsReport from "@/components/StudentsReport.vue";

export default {
  components: {
    navbar,
    EventsReport,
    StudentsReport,
  },
  data() {
    return {
      selectedReport: null
    };
  },
  methods: {
    openReport(reportType) {
      this.selectedReport = reportType;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
.content {
  padding: 20px;
}

.report-buttons {
  display: flex;
  margin:  auto;
  flex-direction: raw;
  justify-content: center;
  margin-bottom: 20px;
}

.report-section {
  margin-bottom: 20px;
}

.report-btn {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.report-btn:hover {
  background-color: #0056b3;
}
</style>