<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <div class="header">
            Для выполнения этого действия необходимо авторизоваться
          </div>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <p>Пожалуйста, авторизуйтесь, чтобы продолжить.</p>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer">
            <button type="button" class="btn-green" @click="goToLogin" aria-label="Перейти к авторизации">
              Перейти к авторизации
            </button>
            <button type="button" class="btn-green" @click="closeModal" aria-label="Закрыть">
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  /* Уменьшил ширину для компактности */
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  text-align: center;
  /* Центрируем текст */
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-green:hover {
  background-color: #ef7d00;
  /* Оранжевый цвет при наведении */
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
</style>