<template>
  <div>
    <navbar />
    <div v-if="isAuthorized" class="registration-report">
      <h1>Отчет о регистрациях на мероприятие "{{ eventId }}"</h1>
      <button @click="downloadExcel">Скачать в Excel</button>
      <table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Отчество</th>
            <th>Тип активности</th>
            <th>Уровень образования</th>
            <th>Курс</th>
            <th>Направление обучения</th>
            <th>Другой тип активности</th>
            <th>Страна</th>
            <th>Федеральный округ</th>
            <th>Регион</th>
            <th>Город</th>
            <th>Телефон</th>
            <th>Email</th>
            <th>Академическая степень</th>
            <th>Академическое звание</th>
            <th>Тип организации</th>
            <th>Другой тип организации</th>
            <th>Название организации</th>
            <th>Форма участия</th>
            <th>Тема доклада</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="registration in registrations" :key="registration.id">
            <td>{{ registration.userid }}</td>
            <td>{{ registration.lastname }}</td>
            <td>{{ registration.firstname }}</td>
            <td>{{ registration.middlename }}</td>
            <td>{{ registration.activitytype }}</td>
            <td>{{ registration.educationlevel }}</td>
            <td>{{ registration.course }}</td>
            <td>{{ registration.studydirection }}</td>
            <td>{{ registration.otheractivitytype }}</td>
            <td>{{ registration.country }}</td>
            <td>{{ registration.feddistrict }}</td>
            <td>{{ registration.region }}</td>
            <td>{{ registration.city }}</td>
            <td>{{ registration.phone }}</td>
            <td>{{ registration.email }}</td>
            <td>{{ registration.academicdegree }}</td>
            <td>{{ registration.academictitle }}</td>
            <td>{{ registration.organizationtype }}</td>
            <td>{{ registration.customorganizationtype }}</td>
            <td>{{ registration.organizationname }}</td>
            <td>{{ registration.participationform }}</td>
            <td>{{ registration.reporttopic }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="auth-required-message">
      <h2>Доступ запрещен</h2>
      <p v-if="!user">Для просмотра этой страницы необходимо авторизоваться.</p>
      <p v-else>У вас недостаточно прав для просмотра этой страницы.</p>
      <button class="form-btn" @click="goToLogin">
        <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
      </button>
    </div>
  </div>
</template>

<script>
import navbar from "@/components/navbar.vue";
import { mapActions, mapGetters } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  components: {
    navbar,
  },
  props: {
    eventName: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('eventregistration', ['registrations']),
    ...mapGetters('user', ['user']), // Добавляем состояние пользователя
    isAuthorized() {
      // Проверяем, авторизован ли пользователь и имеет ли он права администратора
      return this.user && this.user.security === 'admin';
    },
  },
  methods: {
    ...mapActions('eventregistration', ['getEventRegistrationsByEventUid']),
    downloadExcel() {
      const data = this.registrations.map(registration => ({
        'User ID': registration.userid,
        'Фамилия': registration.lastname,
        'Имя': registration.firstname,
        'Отчество': registration.middlename,
        'Тип активности': registration.activitytype,
        'Уровень образования': registration.educationlevel,
        'Курс': registration.course,
        'Направление обучения': registration.studydirection,
        'Другой тип активности': registration.otheractivitytype,
        'Страна': registration.country,
        'Федеральный округ': registration.feddistrict,
        'Регион': registration.region,
        'Город': registration.city,
        'Телефон': registration.phone,
        'Email': registration.email,
        'Академическая степень': registration.academicdegree,
        'Академическое звание': registration.academictitle,
        'Тип организации': registration.organizationtype,
        'Другой тип организации': registration.customorganizationtype,
        'Название организации': registration.organizationname,
        'Форма участия': registration.participationform,
        'Тема доклада': registration.reporttopic,
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Регистрации');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer, 'registrations.xlsx');
    },
    saveAsExcelFile(buffer, fileName) {
      const data = new Blob([buffer], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    goToLogin() {
      this.$router.push('/login'); // Переход на страницу авторизации
    },
  },
  async created() {
    if (this.isAuthorized) {
      await this.getEventRegistrationsByEventUid(this.eventId);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.registration-report {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* Стили для сообщения о необходимости авторизации */
.auth-required-message {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-required-message h2 {
  color: #3c218c;
  margin-bottom: 10px;
}

.auth-required-message p {
  color: #666;
  margin-bottom: 20px;
}

.auth-required-message .form-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-required-message .form-btn:hover {
  background-color: #ef7d00;
}
</style>  