<template>
  <div></div>
</template>

<script>
export default {
  name: 'LoginCallback',
  async mounted() {
    try {
      // Обрабатываем перенаправление
      await this.$msalInstance.handleRedirectPromise();
      this.$router.push({ path: '/DataAddForMS' });
    } catch (error) {
      console.error('Ошибка при обработке перенаправления:', error);
      this.$router.push({ path: '/error' });
    }
  },
};
</script>